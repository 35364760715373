import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/common/`;

// Get list of Contributors user
const getDepartments = async (ID) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}departments`, config);
  if (response.data) {
    return response.data;
  }
};

const getApps = async (ID) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}apps`, config);
  if (response.data) {
    return response.data;
  }
};

const getCSC = async (ID) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}csc`, config);
  if (response.data) {
    return response.data;
  }
};

const commonService = {
  getDepartments,
  getApps,
  getCSC
};

export default commonService;
