import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import userReducers from "../features/users/userSlice";
import siteReducers from "../features/sites/siteSlice";
import shiftReducers from "../features/shifts/shiftSlice";
import profileReducers from "../features/MyProfile/profileSlice";
import timeOffReducers from "../features/timeOff/timeOffSlice";
import commonReducers from "../features/common/commonSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
const presistConfig = {
  key: "root",
  storage,
};
const presistedReducers = persistReducer(
  presistConfig,
  combineReducers({
    auth: authReducer,
    users: userReducers,
    profileData: profileReducers,
    sites: siteReducers,
    shifts: shiftReducers,
    timeoff: timeOffReducers,
    common: commonReducers,
  })
);
export const store = configureStore({
  reducer: presistedReducers,
});

export const presistor = persistStore(store);

setupListeners(store.dispatch);
