import PendingApproval from "../modals/pending-approval/PendingApproval";
import Dummy from "../../assets/images/dummy-profile.jpg";
import Arrow from "../../assets/images/arrow_right.svg";
import { useState } from "react";
import { useSelector } from "react-redux";

const ShiftReplacements = ({
  shiftName,
  siteName,
  date,
  requester,
  userToBeReplaced,
  position,
  startTime,
  endTime,
  disable = false,
  replacement,
}) => {
  const [showReplacementAceptanceModal, setShowReplacementAceptanceModal] =
    useState(false);
  const calculateDuration = (startTime, endTime) => {
    const start = new Date("2000-01-01 " + startTime);
    const end = new Date("2000-01-01 " + endTime);
    const duration = end - start;
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    return { hours, minutes };
  };

  const formatDuration = (startTime, endTime) => {
    const { hours, minutes } = calculateDuration(startTime, endTime);
    if ((hours || minutes) < 0) {
      return `0:0H`;
    } else {
      return `${hours}:${minutes}H`;
    }
  };
  const user = useSelector((state) => state.auth.user);
  return (
    <>
      <div className="vacant_shift shift_replacement mb-2">
        <div className="time_site">
          <h5>{shiftName}</h5>
          <h5>
            {`${new Date(startTime).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}`}
            -
            {`${new Date(endTime).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}`}{" "}
            ({" "}
            {formatDuration(
              new Date(startTime).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }),
              new Date(endTime).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            )}
            )
          </h5>
          <h5>
            {siteName} - {date}
          </h5>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="d-flex align-items-center">
              <img className="dummy" src={Dummy} alt="dummy" />
              <div>
                {" "}
                <p>Requestor:</p>
                <h5>{requester}</h5>
              </div>
            </div>
            <img className="arrow" src={Arrow} alt="Arrow" />
            <div className="d-flex align-items-center">
              <img className="dummy" src={Dummy} alt="dummy" />
              <div>
                <p>Replacement:</p>
                <h5>{userToBeReplaced}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="job">
          <h5>{position}</h5>
          <h4
            onClick={() => {
              if (!disable) setShowReplacementAceptanceModal(true);
            }}
          >
            {user.role == "admin"
              ? replacement?.adminApproval == null
                ? "Pending Approval"
                : replacement?.adminApproval
                ? "Approved"
                : "Rejected"
              : replacement?.userApproval == null
              ? "Pending Approval"
              : replacement?.userApproval
              ? "Approved"
              : "Rejected"}
          </h4>
        </div>
      </div>
      {showReplacementAceptanceModal && (
        <PendingApproval
          shiftName={shiftName}
          siteName={siteName}
          date={date}
          requester={requester}
          userToBeReplaced={userToBeReplaced}
          position={position}
          startTime={startTime}
          endTime={endTime}
          setModalClick={() => setShowReplacementAceptanceModal(false)}
          replacement={replacement}
        />
      )}
    </>
  );
};

export default ShiftReplacements;
