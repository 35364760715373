import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/shift`;
const shiftList = async (shiftBodyData) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(
    `${API_URL}?startDate=${shiftBodyData.startDate}&endDate=${shiftBodyData.endDate}&site=${shiftBodyData.site}&position=${shiftBodyData.position}&user=${shiftBodyData.user}`,
    config
  );
  if (response.data) {
    return response.data;
  }
};

//get to be published shifts
const toBePublishedShifts = async () => {
  const response = await axios.get(`${API_URL}/publish`);
  if (response.data) {
    return response.data;
  }
};

// Get getShiftById
const getShiftById = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  if (response) {
    return response;
  }
};

// Add Shift
const addShiftsData = async (shiftData) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.post(API_URL, shiftData,config);
  if (response) {
    return response;
  }
};

// Assign Shift
const assignShift = async (shiftData) => {
  const response = await axios.put(`${API_URL}/assignShift`, shiftData);
  if (response) {
    return response;
  }
};

const publishShifts = async (shiftBodyData) => {
  const response = await axios.put(`${API_URL}/publish`);
  if (response.data) {
    return response.data;
  }
};

const issueShift = async () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}/issues`, config);
  if (response.data) {
    return response.data;
  }
};

const vacantShift = async () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}/vacant`, config);
  if (response.data) {
    return response.data;
  }
};

const createReplacement = async (shiftBodyData) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.post(
    `${API_URL}/replacement`,
    shiftBodyData,
    config
  );
  if (response.data) {
    return response.data;
  }
};

const getUsersReplacementRequest = async () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}/replacement/user`, config);
  if (response.data) {
    return response.data;
  }
};

const getReplacementRequest = async () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}/replacement`, config);
  if (response.data) {
    return response.data;
  }
};

const approveReplacementRequest = async (data) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.put(`${API_URL}/replacement`, data, config);
  if (response.data) {
    return response.data;
  }
};

const deleteShiftDetail = async (id) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.delete(`${API_URL}/detail/${id}`, config);
  if (response.data) {
    return response.data;
  }
};

const getExceedingUser = async () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  const response = await axios.get(`${API_URL}/exceeding`, config);
  if (response.data) {
    return response.data;
  }
};

const userService = {
  shiftList,
  addShiftsData,
  getShiftById,
  assignShift,
  publishShifts,
  toBePublishedShifts,
  issueShift,
  createReplacement,
  getUsersReplacementRequest,
  getReplacementRequest,
  approveReplacementRequest,
  deleteShiftDetail,
  getExceedingUser,
  vacantShift,
};

export default userService;
