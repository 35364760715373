import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { AppDrawer } from "@bsilobook/component";
import "./layout.scss";
import { Sidebar } from "../pages/sidebar/Sidebar";
import panelLogo from "../assets/images/logo.svg";
import profileSVG from "../assets/images/profile.svg";
import searchIcon from "../assets/images/header-search.svg";
import { getApps } from "../features/common/commonSlice";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import AppIcon from "../assets/images/app.svg";
import { getVersion } from "../utils/common";

export const PanelLayout = () => {
  const dispatch = useDispatch();
  const { apps } = useSelector((state) => state.common);
  const user = useSelector((state) => state?.auth?.user);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [filteredApps, setFilteredApps] = useState([]);

  const userToken = localStorage.getItem("JWT_Token");
  const userOrgId = localStorage.getItem("organization");

  const sideBarMenu = () => {
    setOpenSidebar(!openSidebar);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getApps());
  }, []);

  useEffect(() => {
    if (apps) {
      const mapRes = apps.map((x) => (
        {
          code: x.code,
          label: x.name,
          link: x.link,
          image: x.image ? x.image : AppIcon
        }
      ))
      setFilteredApps(mapRes);
    }
  }, [apps]);


  return (
    <>
      <div className="panel-wrapper flex flex-wrap column-direction">
        <OutsideClickHandler
          onOutsideClick={() => {
            setOpenSidebar(false);
          }}
        >
          <div className={`panel-sidebar ${openSidebar ? "show" : ""}`}>
            <figure>
              <Link to="/">
                <h1 style={{ color: "white" }}>MINTO STUDIOS</h1>
                <span style={{display: "block", fontSize: "16px", fontWeight: "bold", color: "white", marginTop: "0px"}}>Version {getVersion()} </span>
              </Link>
            </figure>
            <Sidebar onClick={() => setOpenSidebar(false)} />
          </div>
        </OutsideClickHandler>
        <div className="panel-main-content flex column-direction">
          <button
            onClick={sideBarMenu}
            className={`side-menu-btn fa fa-bars ${openSidebar ? "show" : ""}`}
          ></button>
          <header className="panel-header items-center flex content-justify-between">
            <div className="left-side">
              <h1>Shift Scheduling</h1>
            </div>
            <div className="right-side">
              <div className="wrap-switch" style={{marginRight: "10px"}}>
                <AppDrawer 
                  apps={filteredApps}
                  token={userToken}
                  orgId={userOrgId}
                  width="40px"
                  height="40px"
                  popupRight="20px"
                  popupTop="70px"
                  bgColor="#ddd"
                />
              </div>
              {/* <form action="" className="header-search"></form> */}
              <figure className="avatar_wrapper flex items-center">
                <img src={user.profile || profileSVG} alt="avatar" />
                <figcaption className="flex column-direction">
                  <strong>{`${user.firstName} ${user.lastName}`}</strong>
                  <small>{user.role}</small>
                </figcaption>
              </figure>
            </div>
          </header>
          <div className="panel-main-wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
