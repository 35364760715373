import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import timeOffService from "./timeOffService";

const initialState = {
  time_off: [],
  time_off_requests: [],
  loading: false,
};

export const createTimeOff = createAsyncThunk(
  "user/createTimeOff",
  async (body, thunkAPI) => {
    try {
      return await timeOffService.createTimeOff(body);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTimeOff = createAsyncThunk(
  "user/getTimeOff",
  async (body, thunkAPI) => {
    try {
      return await timeOffService.getTimeOff(body);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTimeoffRequests = createAsyncThunk(
  "user/getTimeoffRequests",
  async (thunkAPI) => {
    try {
      return await timeOffService.getTimeoffRequests();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const approveTimeOff = createAsyncThunk(
  "user/approveTimeOff",
  async (body, thunkAPI) => {
    try {
      return await timeOffService.approveTimeOff(body);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "timeoff",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTimeOff.fulfilled, (state, action) => {
        state.time_off = action.payload?.timeoffs;
      })
      .addCase(getTimeoffRequests.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(getTimeoffRequests.fulfilled, (state, action) => {
        state.loading = true;
        state.time_off_requests = action.payload?.data;
      });
  },
});

export default userSlice.reducer;
