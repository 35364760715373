import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/auth`;
// url = `http://3.108.146.184:8082/auth/signup`

// const setAuthToken = token => {
//   if (token) {
//     console.log("token get: ", token)
//       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }
//   else{
//       delete axios.defaults.headers.common["Authorization"];
//   }
// }

// Register user
const register = async (userData) => {
  const response = await axios.post(`${API_URL}/login`, userData);
  if (response?.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
  }
};

// Login user
const login = async (userData) => {
  const response = await axios.post(`${API_URL}/login`, userData);
  if (response?.data) {
    localStorage.setItem(
      "organization",
      response?.data?.data?.organizations[0].corpId
    );
    localStorage.setItem("JWT_Token", response?.data?.data?.token);
    // setAuthToken(response?.data?.data?.token)
    return response.data;
  }
};
const selectOrganization = async (header) => {
  const config = {
    headers: {
      Authorization: header.authorization, // Assuming the header object has an "authorization" property
      orgid: header.orgid, // Assuming the header object has an "orgid" property
    },
  };
  const response = await axios.get(API_URL + "/access/organization", config);
  // setAuthToken(response?.data?.data?.token)
  return response.data;
};

const verifyToken = async (userData) => {
  const response = await axios.post(`${API_URL}/verifyToken`, userData);
  if (response?.data) {
    localStorage.setItem(
      "organization",
      response?.data?.data?.orgId
    );
    localStorage.setItem("JWT_Token", response?.data?.data?.token);
    // setAuthToken(response?.data?.data?.token)
    return response.data;
  }
};

const addAreaOIfInterest = async (interest) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_ROUTE}/user/add-interest`,
    interest
  );
  if (response.data) {
    return response.data;
  }
};

// Forget Password
const forgetpassword = async (email) => {
  const response = await axios.post(API_URL + "forget-password", email);
  if (response.data.status === "Success") {
    return response.data;
  }
};

const verifyEmail = async (token) => {
  const response = await axios.get(API_URL + `verify-email/${token}`);
  if (response.data.status === "Success") {
    return response.data;
  }
};

// reset Password
const getResetPass = async (ParamsData) => {
  const response = await axios.get(
    API_URL + `reset-password/${ParamsData.id}/${ParamsData.token}`
  );
  console.log("response.data.status: ", response.data.status);
  if (response.data.status === "Success") {
    return response.data;
  }
};

// Reset Password post
const postResetPass = async (ParamsData) => {
  const response = await axios.post(
    API_URL + `reset-password/${ParamsData.id}/${ParamsData.token}`,
    { password: ParamsData.password }
  );
  console.log("response.data.status: ", response.data.status);
  if (response.data.status === "Success") {
    return response.data;
  }
};

const updatePass = async (passData) => {
  const response = await axios.post(API_URL + "change-password", passData);
  console.log("response.data.status: ", response.data.message);
  return response.data;
};

// Logout user
const Logout = () => {
  localStorage.clear();
};

const authService = {
  register,
  Logout,
  login,
  forgetpassword,
  getResetPass,
  postResetPass,
  updatePass,
  verifyEmail,
  addAreaOIfInterest,
  selectOrganization,
  verifyToken,
};

export default authService;
