import "./login.scss";
import logo from "../../assets/images/logo.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login, reset } from "./../../features/auth/authSlice";
import { getVersion } from "../../utils/common";
import {  toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const Auth = useSelector((state) => state.auth);
  const { user, isLoading, isError, isSuccess, message, isAuthenticated } =
    Auth;
  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  };
  useEffect(() => {
    if (!user) {
      dispatch(reset());
      navigate("/");
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
    if (isAuthenticated == true && message !== "") {
      toast.success(message);
      setTimeout(() => {
        navigate("/select-organization");
      }, 1500);
      dispatch(reset());
    }
  }, [user, isAuthenticated, message]);
  return (
    <>
      <div className="login-wrapper">
        <div className="login-inner-wrap">
          <h3 style={{marginTop: "20px", fontSize: "2.5rem"}}>MINTO STUDIOS</h3>
          <span style={{display: "block", fontSize: "14px", fontWeight: "bold", color: "#07070c", marginTop: "-5px"}}>Version {getVersion()} </span>
          {/* <img src={logo} alt="logo" /> */}
          {/* <h2>Hi, Welcome Back</h2>
          <span>Login to your account</span> */}
          <form action="">
            <ul>
              <li>
                <label htmlFor="">Email Address</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => setemail(e.target.value)}
                />
              </li>
              <li>
                <label htmlFor="">Password</label>
                <div className="password-wrap">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </div>
              </li>
              <li className="login-remember">
                <label htmlFor="remember">
                  <input id="remember" type="checkbox" />
                  Remember Me
                </label>
                <a style={{cursor: "pointer"}} className="" onClick={() => window.open(`${process.env.REACT_APP_EPROFILE_URL}/forgetpassword`, "_blank")}>
                  Forget Password
                </a>
              </li>
              <li>
                <button type="button" onClick={handleSubmit}>
                  Login
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
}
