import { useDispatch, useSelector } from "react-redux";
import cross from "../../../assets/images/close.svg";
import { resetMsg } from "../../../features/shifts/shiftSlice";

const ReplacementDoneModal = ({ setModalClick, user }) => {
  const errorMsg = useSelector((state) => state.shifts);
  const dispatch = useDispatch();
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Replacement Request</h2>
              <img onClick={setModalClick} src={cross} alt="cross" />
            </div>
            <div className="modal-body pt-3">
              <p>
                {errorMsg.replcementMessage
                  ? errorMsg.replcementMessage
                  : `Replacement request sent to
                ${user.firstName} ${user.lastName}, we will notify you when 
                ${user.firstName} ${user.lastName} responds to your swap
                request.`}
              </p>
              <div className="flex content-justify-end mt-5">
                <button
                  className="simple-btn"
                  type="submit"
                  onClick={() => {
                    dispatch(resetMsg());
                    setModalClick();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplacementDoneModal;
