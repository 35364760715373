import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from './profileService'

// Get user from localStorage

// const contrib = JSON.parse(localStorage.getItem('contributors'))

const initialState = {
  profile_data: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  imageLocation: '',
}

// get contributer list
export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (ID,thunkAPI) => {
    try {
      return await profileService.profileData(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// get contributer list
export const updateLastLogin = createAsyncThunk(
  'profile/updateLastLogin',
  async (ID,thunkAPI) => {
    try {
      return await profileService.updateLastLogin(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// export const addContributors = createAsyncThunk(
//   'contrib/addContributors',
//   async (userData,thunkAPI) => {
//     try {
//       return await contribService.addContributorsData(userData)
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       return thunkAPI.rejectWithValue(message)
//     }
//   }
// )

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (userData,thunkAPI) => {
    try {
      return await profileService.updateProfileData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const saveImage = createAsyncThunk(
  'profile/saveImage',
  async (bodyFormData,thunkAPI) => {
    try {
      return await profileService.saveImage(bodyFormData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// export const deleteContributors = createAsyncThunk(
//   'contrib/deleteContributors',
//   async (ID,thunkAPI) => {
//     try {
//       return await contribService.deleteContributorsData(ID)
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       return thunkAPI.rejectWithValue(message)
//     }
//   }
// )

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    resetImage: (state) => {
      state.imageLocation = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.message = action.payload.message
        state.profile_data = action.payload.data 
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.profile_data = null
      })
      
      .addCase(updateLastLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateLastLogin.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.message = action.payload.data.message
        state.profile_data = action.payload.data 
      })
      .addCase(updateLastLogin.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.profile_data = null
      })

      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.profile_data = action.payload.data 
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.profile_data = state.profile_data
      })
      
      .addCase(saveImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(saveImage.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.message = action.payload
        state.imageLocation = action.payload.data
        // state.profile_data = action.payload.data 
      })
      .addCase(saveImage.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        // state.message = action.payload
        state.profile_data = null
      })
  },
})

export const { reset, resetImage } = profileSlice.actions
export default profileSlice.reducer