import DataTable from "react-data-table-component";

const Data = (props) => {
  return (
    <DataTable
      columns={props.columns}
      data={props.userList}
      responsive={true}
      onRowClicked={props.onRowClicked}
      sortFunction={null}
    />
  );
};

export default Data;
