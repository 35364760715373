import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

// Get user from localStorage
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isAuthenticated: false,
  sentLinkSuccess: false,
  sentLinkError: false,
  changeSuccess: false,
  changeError: false,
  isVerified: false,
};

// Register user
export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const selectOrganization = createAsyncThunk(
  "auth/selectOrganization",
  async (user, thunkAPI) => {
    try {
      return await authService.selectOrganization(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyToken = createAsyncThunk("auth/verifyToken", async (user, thunkAPI) => {
  try {
    return await authService.verifyToken(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// forget password
export const frogetPass = createAsyncThunk(
  "auth/forgetPass",
  async (email, thunkAPI) => {
    try {
      return await authService.forgetpassword(email);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// reset password
export const getResetPass = createAsyncThunk(
  "auth/getResetPass",
  async (ParamsData, thunkAPI) => {
    try {
      return await authService.getResetPass(ParamsData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// reset password post
export const postResetPass = createAsyncThunk(
  "auth/postResetPass",
  async (ParamsData, thunkAPI) => {
    try {
      return await authService.postResetPass(ParamsData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update password post
export const updatePass = createAsyncThunk(
  "auth/updatePass",
  async (passData, thunkAPI) => {
    try {
      return await authService.updatePass(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// varify Email
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (token, thunkAPI) => {
    try {
      return await authService.verifyEmail(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// add area of interest
export const addAreaOIfInterest = createAsyncThunk(
  "auth/addAreaOIfInterest",
  async (interest, thunkAPI) => {
    try {
      return await authService.addAreaOIfInterest(interest);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.Logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.sentLinkSuccess = false;
      state.sentLinkError = false;
      state.changeSuccess = false;
      state.changeError = false;
      state.isVerified = false;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(addAreaOIfInterest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAreaOIfInterest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(addAreaOIfInterest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = "First-Step completed successfully!";
        state.user = action.payload.data.user;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(selectOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = "User is Login successfully!";
        state.isAuthenticated = true;
      })
      .addCase(selectOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verifyToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = "First-Step completed successfully!";
        state.user = action.payload.data.user;
        state.isAuthenticated = true;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isVerified = true;
        // state.user = action.payload
        state.message = action.payload;
        // state.isAuthenticated=true
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // state.message = action.payload
      })

      .addCase(frogetPass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(frogetPass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sentLinkSuccess = true;
        // state.user = action.payload
        state.message = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(frogetPass.rejected, (state, action) => {
        state.isLoading = false;
        state.sentLinkError = true;
        state.message = action.payload;
        state.sentLink = false;
        // state.user = null
      })

      .addCase(updatePass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.changeSuccess = true;
        state.changeError = false;
        // state.user = action.payload
        state.message = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(updatePass.rejected, (state, action) => {
        state.isLoading = false;
        state.changeError = true;
        state.changeSuccess = false;
        state.message = action.payload;
        // state.user = null
      })

      .addCase(getResetPass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResetPass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.user = action.payload
        state.message = action.payload;
      })
      .addCase(getResetPass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null
      })

      .addCase(postResetPass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postResetPass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.user = action.payload
        state.message = action.payload;
      })
      .addCase(postResetPass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null
      })

      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
