import React, { useEffect, useState } from "react";
import "./timeOff.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import DataTable from "react-data-table-component";
import ExportData from "./ExportData.json";
import AddTimeOffModal from "../../components/modals/add-time-off/AddTimeOffModal";
import { DatePicker, DateRangePicker } from "rsuite";
import Multiselect from "multiselect-react-dropdown";
import plusBtn from "../../assets/images/plus.svg";
import searchIcon from "../../assets/images/header-search.svg";
import exportIcon from "../../assets/images/export.svg";
import timer from "../../assets/images/timer.svg";
import TimeOffRequest from "../../components/modals/time-off-request/TimeOffRequest";
import { useDispatch, useSelector } from "react-redux";
import { getTimeOff } from "../../features/timeOff/timeOffSlice";
import { differenceInDays, format } from "date-fns";
import { CSVLink } from "react-csv";
import moment from "moment";
export default function TimeOff() {
  const [openAddTimeOffModal, setOpenAddTimeOffModal] = useState(false);
  const [show, setShow] = useState(false);
  const { time_off } = useSelector((state) => state.timeoff);
  const user = useSelector((state) => state.auth.user);
  const [filterData, setFilterData] = useState(time_off);
  const [searchValues, setSearchValues] = useState({
    timeOff: "",
    status: "",
    username: "",
    date: ["", ""],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (openAddTimeOffModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openAddTimeOffModal]);
  useEffect(() => {
    dispatch(getTimeOff());
  }, []);
  const handleSearch = (key, value) => {
    setSearchValues((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    dispatch(getTimeOff());
  }, [openAddTimeOffModal, show]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const columns = [
    {
      name: <div className="table-content">Username</div>,
      selector: (row) => (
        <div className="jobPosition">{`${row?.userId?.firstName} ${row?.userId?.lastName}`}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Start Time</div>,
      selector: (row) => (
        <div className="jobPosition">
          {`${new Date(row?.startDate).toLocaleString()}`}
        </div>
      ),
    },
    {
      name: <div className="table-content">End time</div>,
      selector: (row) => (
        <div className="jobPosition">{`${new Date(
          row?.endDate
        ).toLocaleString()}`}</div>
      ),
    },
    {
      name: <div className="table-content">Duration</div>,
      selector: (row) => (
        <div className="jobPosition">{row?.timeOffDetail?.length} Day</div>
      ),
    },
    {
      name: <div className="table-content">Time Off</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row?.type == "sickLeave" ? "Sick Leave" : "Time Off"}
        </div>
      ),
    },
    {
      name: <div className="table-content">Status</div>,
      selector: (row) => (
        <div
          style={{
            background:
              row?.status == "pending"
                ? "#D4D4D4"
                : row?.status == "rejected"
                ? "#FFA5A5"
                : "#A5F2CE",
            color:
              row?.status == "pending"
                ? "#737373"
                : row?.status == "rejected"
                ? "#BD4343"
                : "#2E8E62",
            textTransform: "capitalize",
            borderRadius: "57px",
          }}
          className="jobPosition"
        >
          <span>{row?.status}</span>
        </div>
      ),
    },
  ];
  useEffect(() => {
    const { status, timeOff, username, date } = searchValues;
    setFilterData(
      time_off.filter((el) => {
        return (
          el?.status?.toLowerCase().includes(status?.toLowerCase()) &&
          el?.type?.toLowerCase().includes(timeOff?.toLowerCase()) &&
          el?.userId?.firstName
            ?.toLowerCase()
            .includes(username?.toLowerCase()) &&
          (!date[0] ||
            (+new Date(el?.startDate) >= +new Date(date[0]) &&
              +new Date(el.endDate) <= +new Date(date[1])))
        );
      })
    );
  }, [searchValues]);

  const csvData = [
    ["Username", "Start Date", "End Date", "Duration", "Time off", "Status"],
    ...time_off.map(
      ({ name, startDate, endDate, timeOffDetail, status, type }) => [
        name,
        moment(startDate).format("l"),
        moment(endDate).format("l"),
        `${timeOffDetail?.length} Day`,
        type,
        status,
      ]
    ),
  ];

  return (
    <>
      {openAddTimeOffModal === true && (
        <AddTimeOffModal setModalClick={() => setOpenAddTimeOffModal(false)} />
      )}
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop="static"
        placement="end"
      >
        <TimeOffRequest handleClose={handleClose} />
      </Offcanvas>

      <div className="panel-card">
        <div className="panel-body">
          <div className="search-wrappers">
            <div className="search-left-side"></div>
            <div className="search-right-side flex">
              <CSVLink filename="time-off.csv" data={csvData}>
                <button className="export-btn">
                  <img src={exportIcon} alt="exportIcon" />
                  Export
                </button>
              </CSVLink>
              {user?.role == "admin" && (
                <button className="export-btn" onClick={handleShow}>
                  <img src={timer} alt="timer" />
                  View Requests
                </button>
              )}
              <button
                className="theme-btn"
                onClick={() => setOpenAddTimeOffModal(true)}
              >
                <img src={plusBtn} alt="plusBtn" />
                {user?.role == "admin" ? "Add Time Off" : "Request Time Off"}
              </button>
            </div>
          </div>
          <div className="search-wrappers">
            <div className="search-right-side flex">
              <Multiselect
                singleSelect
                displayValue="cat"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {
                  handleSearch("timeOff", "");
                }}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck(e) {
                  handleSearch("timeOff", e[0].key);
                }}
                placeholder="All Time Off"
                options={[
                  {
                    cat: "All",
                    key: "all",
                  },
                  {
                    cat: "Time Off",
                    key: "timeOff",
                  },
                  {
                    cat: "Sick Leave",
                    key: "sickLeave",
                  },
                ]}
                showCheckbox
              />
              <Multiselect
                singleSelect
                displayValue="key"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {
                  handleSearch("status", "");
                }}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck(e) {
                  if (e[0].key === "all") {
                    handleSearch("status", "");
                  } else {
                    handleSearch("status", e[0].key);
                  }
                }}
                placeholder="All Status"
                options={[
                  {
                    cat: "all",
                    key: "all",
                  },
                  {
                    cat: "approved",
                    key: "approved",
                  },
                  {
                    cat: "rejected",
                    key: "rejected",
                  },
                ]}
                showCheckbox
              />
              <div className="week-selector">
                <DateRangePicker
                  placeholder="yyyy-mm-dd ~ yyyy-mm-dd"
                  onChange={(e) => {
                    handleSearch("date", e ? e : ["", ""]);
                  }}
                />
              </div>
              <form action="" className="header-search">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    handleSearch("username", e.target.value);
                  }}
                />
                <button>
                  <img src={searchIcon} alt="searchIcon" />
                </button>
              </form>
            </div>
            <div className="search-left-side"></div>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={filterData}
              pagination={true}
              responsive={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
