import React from "react";
import "./CustomCheckBoxes.scss";
export default function CustomCheckBoxes({ value, setValue, flag = false }) {
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.checked,
    });
  };
  return (
    <div className="custom_checkboxes">
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input1"
          name={"Sunday"}
          onChange={handleChange}
          defaultChecked={value["Sunday"]}
        />
        <span className="checkbox_inner1">S</span>
      </label>
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input2"
          name={"Monday"}
          onChange={handleChange}
          defaultChecked={value["Monday"]}
        />
        <span className="checkbox_inner2">M</span>
      </label>
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input3"
          name={"Tuesday"}
          onChange={handleChange}
          defaultChecked={value["Tuesday"]}
        />
        <span className="checkbox_inner3">T</span>
      </label>
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input4"
          name={"Wednesday"}
          onChange={handleChange}
          defaultChecked={value["Wednesday"]}
        />
        <span className="checkbox_inner4">W</span>
      </label>
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input5"
          name={"Thursday"}
          onChange={handleChange}
          defaultChecked={value["Thursday"]}
        />
        <span className="checkbox_inner5">T</span>
      </label>
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input6"
          name={"Friday"}
          onChange={handleChange}
          defaultChecked={value["Friday"]}
        />
        <span className="checkbox_inner6">F</span>
      </label>
      <label className="checkbox">
        <input
          disabled={flag}
          type="checkbox"
          className="checkbox_input7"
          name={"Saturday"}
          onChange={handleChange}
          defaultChecked={value["Saturday"]}
        />
        <span className="checkbox_inner7">S</span>
      </label>
    </div>
  );
}
