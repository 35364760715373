import React, { useState, useEffect } from "react";
import "./RequestPending.scss";
import Avatar from "../../../../assets/images/avatar.png";
import Subtract from "../../../../assets/images/Subtract.svg";
import SubtractTick from "../../../../assets/images/SubtractTick.svg";
import RejectTimeOffModal from "../../reject-time-off/RejectTimeOffModal";
import { useDispatch, useSelector } from "react-redux";
import {
  approveTimeOff,
  getTimeoffRequests,
} from "../../../../features/timeOff/timeOffSlice";
import { format, intervalToDuration } from "date-fns";
export default function RequestPending() {
  const [openRejectTimeOffModal, setOpenRejectTimeOffModal] = useState(false);
  const [selectedRequest, setselectedRequest] = useState("");

  const dispatch = useDispatch();
  const { time_off_requests } = useSelector((state) => state.timeoff);
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    dispatch(getTimeoffRequests());
  }, []);
  useEffect(() => {
    setRequests(time_off_requests);
  }, [time_off_requests]);
  useEffect(() => {
    if (openRejectTimeOffModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
      dispatch(getTimeoffRequests());
    }
  }, [openRejectTimeOffModal]);
  return (
    <>
      {openRejectTimeOffModal === true && (
        <RejectTimeOffModal
          selectedRequest={selectedRequest}
          setModalClick={() => setOpenRejectTimeOffModal(false)}
        />
      )}
      <div className="request_pending">
        {requests?.map((request) => (
          <div className="card">
            <div className="requester_info">
              <img
                src={request.userId.coverUrl || request.userId.profile}
                alt="avatar"
              />
              <figure>
                <h5>{`${request.userId.firstName} ${request.userId.lastName}`}</h5>
              </figure>
            </div>
            <p>
              Requested on {format(new Date(request.createdAt), "EEEE")},{" "}
              {new Date(request.createdAt).toLocaleString()}
            </p>
            <div className="unpaid_leave">
              <h5>
                {request.type == "timeOff" ? "Unpaid Leave" : "Sick Leave"}
              </h5>
              <ul className="list-style-none">
                <li>
                  <p>Start Time</p>{" "}
                  <p>
                    {format(new Date(request.startDate), "EEEE")},{" "}
                    {new Date(request.startDate).toLocaleString()}
                  </p>
                </li>
                <li>
                  <p>End Time</p>{" "}
                  <p>
                    {format(new Date(request.endDate), "EEEE")},{" "}
                    {new Date(request.endDate).toLocaleString()}
                  </p>
                </li>
                <li>
                  <p>Details</p>{" "}
                  <p>
                    {
                      intervalToDuration({
                        start: new Date(request.startDate),
                        end: new Date(request.endDate),
                      }).days
                    }{" "}
                    Day -{" "}
                    {intervalToDuration({
                      start: new Date(request.startDate),
                      end: new Date(request.endDate),
                    }).days
                      ? intervalToDuration({
                          start: new Date(request.startDate),
                          end: new Date(request.endDate),
                        }).hours
                      : intervalToDuration({
                          start: new Date(request.startDate),
                          end: new Date(request.endDate),
                        }).days * 8}{" "}
                    Work Hours
                  </p>
                </li>
                <li>
                  <p>Description</p> <p>{request.note}</p>
                </li>
              </ul>
            </div>
            <div className="flex content-justify-end">
              <button
                className="simple-btn reject-btn me-2"
                type="submit"
                onClick={() => {
                  setselectedRequest(request._id);
                  setOpenRejectTimeOffModal(true);
                }}
              >
                <img className="me-2" src={Subtract} alt="Subtract" /> Reject
                Time Off
              </button>
              <button
                className="simple-btn simple-btn-hover"
                type="submit"
                onClick={async () => {
                  await dispatch(
                    approveTimeOff({
                      id: request._id,
                      approval: true,
                    })
                  );
                  await dispatch(getTimeoffRequests());
                }}
              >
                <img className="me-2" src={SubtractTick} alt="SubtractTick" />
                Approve Time Off
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
