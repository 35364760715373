import "./sitesManagement.scss";
import Search from "../../components/search/Search";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import AddNewSite from "../../components/modals/add-new-site/AddNewSite";
import { getSites, reset } from "../../features/sites/siteSlice";
import { useDispatch, useSelector } from "react-redux";

export default function SitesManagement() {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [siteFilteredData, setSiteFilteredData] = useState([]);
  const { Sites_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.sites);
  const dispatch = useDispatch();
  const columns = [
    {
      name: <div className="table-content">Site Name</div>,
      selector: (row) => row.siteName,
      wrap: true,
    },
    {
      name: <div className="table-content">Address</div>,
      selector: (row) => row.siteAddress,
    },
    {
      name: <div className="table-content">Job Positions</div>,
      selector: (row) => row.siteJobPositions,
    },
    {
      name: <div className="table-content">Employees Assigned</div>,
      selector: (row) => row.siteEmployees,
      // width: '220px'
    },
  ];
  const makeRow = (siteArr) => {
    var arr =
      Array.isArray(siteArr) && siteArr.length > 0
        ? siteArr.map((data, id) => ({
            siteName: <>{data.name}</>,
            siteAddress: <>{data.streetAddress}</>,
            siteJobPositions: <>{data.jobPositions?.length}</>,
            siteEmployees: <>{0}</>,
          }))
        : [];
    setSiteList(arr);
    setSiteFilteredData(arr);
  };
  const handleSearch = (searchText) => {
    setSearchQuery(searchText);
  };

  useEffect(() => {
    dispatch(getSites());
  }, [openAddUser, message]);
  useEffect(() => {
    if (Sites_List) {
      const data = [...Sites_List].reverse();
      makeRow(data);
    }
    if (isSuccess == true) {
      dispatch(reset());
    }
  }, [dispatch, isError, message, isSuccess, isList]);
  useEffect(() => {
    if (openAddUser) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openAddUser]);

  return (
    <>
      {openAddUser === true && (
        <AddNewSite setModalClick={() => setOpenAddUser(false)} />
      )}
      {/* <div className="head-title">
        <h2>Sites Managements</h2>
      </div> */}
      <div className="panel-card">
        <div className="panel-body">
          <Search
            handleSearch={handleSearch}
            buttonText="Add New Sites"
            setModalClick={() => setOpenAddUser(true)}
            exportBtn={true}
          />
          <div className="table-responsive alternative-colors">
            <DataTable
              columns={columns}
              data={siteList?.filter((row) => {
                if (searchQuery === "") return siteList;
                const siteName = row?.siteName?.props?.children?.toLowerCase();
                const siteAddress =
                  row?.siteAddress?.props?.children?.toLowerCase();
                const siteJobPositions =
                  typeof row?.siteJobPositions?.props.children === "string"
                    ? row?.siteJobPositions?.props.children.toLowerCase()
                    : "";
                return (
                  siteName.includes(searchQuery.toLowerCase()) ||
                  siteAddress.includes(searchQuery.toLowerCase()) ||
                  siteJobPositions.includes(searchQuery.toLowerCase())
                );
              })}
              pagination={true}
              responsive={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
