import React, { useLayoutEffect } from "react";
import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getToBePublishedShifts,
  publishShifts,
} from "../../../features/shifts/shiftSlice";
import { format } from "date-fns";

export default function AddShift(props) {
  const dispatch = useDispatch();
  const { toBePublishedShifts } = useSelector((state) => state.shifts);
  useLayoutEffect(() => {
    dispatch(getToBePublishedShifts());
  }, []);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date("2000-01-01 " + startTime);
    const end = new Date("2000-01-01 " + endTime);
    const duration = end - start;

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return { hours, minutes };
  };

  const formatDuration = (startTime, endTime) => {
    const { hours } = calculateDuration(startTime, endTime);
    return hours;
  };
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Publish Shifts</h2>
              <img onClick={props.setModalClick} src={cross} alt="cross" />
            </div>
            <div className="modal-body">
              <div className="publish_shift">
                <div className="heading">
                  <h5>Weekend Shift</h5>
                </div>
                <span className="line"></span>
                {toBePublishedShifts?.map((shift) => (
                  <div className="heading">
                    <p>
                      Shifts Added {shift.position} Position (
                      {format(new Date(shift.date), "E, d LLL")})
                    </p>
                    <p>1 Shifts</p>
                  </div>
                ))}
                <span className="line"></span>
                <div className="heading">
                  <h5>Total Shift(s)</h5>
                  <h5>{toBePublishedShifts?.length} Shifts</h5>
                </div>
                <div className="heading">
                  <h5>Total Hour(s)</h5>
                  <h5>
                    {toBePublishedShifts?.reduce(
                      (previousValue, currentValue) => {
                        return (
                          previousValue +
                          formatDuration(
                            new Date(currentValue.startTime).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            ),
                            new Date(currentValue.endTime).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )
                          )
                        );
                      },
                      0
                    ) || 0}{" "}
                    Hours
                  </h5>
                </div>
              </div>
              <div className="flex content-justify-end mt-5">
                <button
                  disabled={!toBePublishedShifts?.length}
                  className="simple-btn"
                  type="submit"
                  onClick={() => {
                    dispatch(publishShifts());
                    props.setModalClick();
                  }}
                >
                  Publish Shift
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
