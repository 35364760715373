import { Link, NavLink, useNavigate } from "react-router-dom";
import "./sidebar.scss";
import icon1 from "../../assets/images/date_range.svg";
import icon2 from "../../assets/images/pin.svg";
import icon3 from "../../assets/images/time.svg";
import bottomIcon1 from "../../assets/images/support.svg";
import bottomIcon2 from "../../assets/images/settings.svg";
import bottomIcon3 from "../../assets/images/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/auth/authSlice";

export const Sidebar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  return (
    <>
      <ul className="list-style-none main-menu">
        {user.role == "admin" && (
          <li>
            <NavLink
              to="/dashboard/users-management"
              onClick={props.onClick}
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              <img src={icon1} alt="icon" /> User Management
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            to="/dashboard/shift-management"
            onClick={props.onClick}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            <img src={icon1} alt="icon" /> Scheduling
          </NavLink>
        </li>
        {user.role == "admin" && (
          <li>
            <NavLink
              to="/dashboard/sites-management"
              onClick={props.onClick}
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              <img src={icon2} alt="icon" /> Sites Management
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            to="/dashboard/time-off"
            onClick={props.onClick}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            <img src={icon3} alt="icon" /> Time Off
          </NavLink>
        </li>
      </ul>
      <ul className="bottom-area list-style-none">
        <li>
          <NavLink
            to="/dashboard/help-support"
            onClick={props.onClick}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            <img src={bottomIcon1} alt="icon" /> Help & support
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/setting"
            onClick={props.onClick}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            <img src={bottomIcon2} alt="icon" /> Settings
          </NavLink>
        </li>
        <li>
          <Link
            to=""
            onClick={() => {
              dispatch(logout());
              dispatch(reset());
              navigate("/");
              props.onClick();
            }}
          >
            <img src={bottomIcon3} alt="icon" /> Logout
          </Link>
        </li>
      </ul>
    </>
  );
};
