import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const initialState = {
  Users_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isList: false,
};

export const getUsers = createAsyncThunk("user/getUsers", async (thunkAPI) => {
  try {
    return await userService.usersList();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addUsers = createAsyncThunk(
  "user/addUsers",
  async (userData, thunkAPI) => {
    try {
      const result = await userService.addUsersData(userData);
      return result;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editUsers = createAsyncThunk(
  "user/editUsers",
  async (userData, thunkAPI) => {
    try {
      return await userService.editUsersData(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "user/deleteUsers",
  async (ID, thunkAPI) => {
    try {
      return await userService.deleteUsersData(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveImageU = createAsyncThunk(
  "user/saveImageU",
  async (bodyFormData, thunkAPI) => {
    try {
      return await userService.saveImageU(bodyFormData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePassU = createAsyncThunk(
  "user/updatePass",
  async (passData, thunkAPI) => {
    try {
      return await userService.updatePassU(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletedRowsU = createAsyncThunk(
  "user/deletedRowsS",
  async (passData, thunkAPI) => {
    try {
      return await userService.deletedRowsU(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReplacementUsers = createAsyncThunk(
  "user/getReplacementUsers",
  async (shiftId, thunkAPI) => {
    try {
      return await userService.getReplacementUsers(shiftId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId, thunkAPI) => {
    try {
      return await userService.deleteUser(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (payload, thunkAPI) => {
    try {
      return await userService.updateUser(payload.id, payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.isList = false;
    },
    resetImage: (state) => {
      state.imageLocation = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isList = true;
        state.Users_List = action.payload?.data;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(addUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "user is created successfully!";
      })
      .addCase(addUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = "User Email already exists!";
        state.Users_List = null;
      })

      .addCase(editUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(editUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(deleteUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(saveImageU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveImageU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imageLocation = action.payload.data;
      })
      .addCase(saveImageU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(updatePassU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.data;
      })
      .addCase(updatePassU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      .addCase(deletedRowsU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedRowsU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.data;
      })
      .addCase(deletedRowsU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getReplacementUsers.fulfilled, (state, action) => {
        state.replacementUsers = action.payload?.data;
      });
  },
});

export const { resetUser, resetImage } = userSlice.actions;
export default userSlice.reducer;
