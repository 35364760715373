import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import RequestPending from "./request-pending/RequestPending";
import { useSelector } from "react-redux";

export default function TimeOffRequest(props) {
  const { time_off_requests } = useSelector((state) => state.timeoff);
  return (
    <>
      <Offcanvas.Header closeButton onHide={props.closeModal}>
        <Offcanvas.Title>
          Request ({time_off_requests?.length || 0})
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <RequestPending />
      </Offcanvas.Body>
    </>
  );
}
