import "./login.scss";
import logo from "../../assets/images/logo.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  login,
  selectOrganization,
  reset,
} from "./../../features/auth/authSlice";
import {  toast } from "react-toastify";
import { getVersion } from "../../utils/common";

export default function Organization() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [authorization, setAuthorization] = useState("");
  const [orgid, setOrgid] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const Auth = useSelector((state) => state.auth);
  const { user, isLoading, isError, isSuccess, message, isAuthenticated } =
    Auth;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedOrg !== "Shift-Scheduling") {
      toast.error("Please select organization!");
    } else {
      const header = {
        authorization: authorization,
        orgid: orgid,
      };
      dispatch(selectOrganization(header));
    }
  };
  useEffect(() => {
    setAuthorization(localStorage.getItem("JWT_Token"));
    const getOrganizations = localStorage.getItem("organization");
    setOrgid(getOrganizations);
  }, []);
  useEffect(() => {
    if (!user) {
      dispatch(reset());
      navigate("/select-organization");
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
    if (isAuthenticated == true && message !== "") {
      toast.success(message);
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
      dispatch(reset());
    }
  }, [user, isAuthenticated, message]);
  return (
    <>
      <div className="login-wrapper">
        <div className="login-inner-wrap">
          {/* <img src={logo} alt="logo" /> */}
          <h3 style={{marginTop: "20px", fontSize: "2.5rem"}}>MINTO STUDIOS</h3>
          <span style={{display: "block", fontSize: "14px", fontWeight: "bold", color: "#07070c", marginTop: "-5px"}}>Version {getVersion()} </span>
          <h2>Select Organization</h2>
          {/* <span>Choose your organization team</span> */}
          <form action="">
            <ul>
              <li>
                <label htmlFor="">Organization</label>
                <select
                  name="department"
                  onChange={(e) => setSelectedOrg(e.target.value)}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="">Select Organization</option>
                  <option value="Shift-Scheduling">Shift-Scheduling</option>
                </select>
              </li>
              <li>
                <button type="button" onClick={handleSubmit}>
                  Login
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
}
