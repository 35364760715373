import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/users/`;

// Get list of Contributors user
const profileData = async (ID) => {
  const response = await axios.get(API_URL + `profile-data/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const updateLastLogin = async (ID) => {
  const response = await axios.put(API_URL + `update-login/${ID}`);
  if (response.data) {
    return response.data;
  }
};

// Add Image Data
const saveImage = async (bodyFormData) => {
  const authToken = localStorage.getItem("JWT_Token");
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authToken,
      orgid: organization,
    },
  };
  const response = await axios.post(
    API_URL + "profileImageUpload/s3",
    bodyFormData,
    config
  );
  if (response.data) {
    return response.data;
  }
};

const updateProfileData = async (userData) => {
  const response = await axios.put(API_URL + "edit-profile", userData);
  if (response.data) {
    return response.data;
  }
};

// const deleteContributorsData = async (ID) => {
//   const response = await axios.put(API_URL + `delete-contributors/${ID}`);
//   if (response.data) {
//       return response.data
//   }
// }

// git checkout -b ali_dev

const profileService = {
  profileData,
  saveImage,
  updateProfileData,
  updateLastLogin,
  // deleteContributorsData,
};

export default profileService;
