import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shiftService from "./shiftService";

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  Shift_List: [],
  Shift_List_Export: [],
  Shift: [],
  exceedingUser: [],
  issueShift: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isList: false,
};

// get contributer list
export const getShifts = createAsyncThunk(
  "shift/getShifts",
  async (shiftBodyData, thunkAPI) => {
    try {
      return await shiftService.shiftList(shiftBodyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const exportShift = createAsyncThunk(
  "shift/exportShift",
  async (shiftBodyData, thunkAPI) => {
    try {
      return await shiftService.shiftList(shiftBodyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShiftById = createAsyncThunk(
  "shift/getShiftById",
  async (id, thunkAPI) => {
    try {
      return await shiftService.getShiftById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addShifts = createAsyncThunk(
  "shift/addShifts",
  async (siteData, thunkAPI) => {
    try {
      return await shiftService.addShiftsData(siteData);
    } catch (error) {
      const message =
        (error && error?.response?.data?.errors[0]?.msg) ||
        error?.response?.data?.errors[0] ||
        error?.response?.data?.errors[0]?.msg?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const assignShift = createAsyncThunk(
  "shift/assignShift",
  async (shiftBodyData, thunkAPI) => {
    try {
      return await shiftService.assignShift(shiftBodyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const publishShifts = createAsyncThunk(
  "shift/publish",
  async (shiftBodyData, thunkAPI) => {
    try {
      return await shiftService.publishShifts(shiftBodyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getToBePublishedShifts = createAsyncThunk(
  "shift/getToBePublishedShifts",
  async (thunkAPI) => {
    try {
      return await shiftService.toBePublishedShifts();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShiftWithIssues = createAsyncThunk(
  "shift/getShiftWithIssues",
  async (thunkAPI) => {
    try {
      return await shiftService.issueShift();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const vacantShift = createAsyncThunk(
  "shift/vacantShift",
  async (thunkAPI) => {
    try {
      return await shiftService.vacantShift();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createReplacement = createAsyncThunk(
  "shift/createReplacement",
  async (data, thunkAPI) => {
    try {
      return await shiftService.createReplacement(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUsersReplacementRequest = createAsyncThunk(
  "shift/userReplacement",
  async (thunkAPI) => {
    try {
      return await shiftService.getUsersReplacementRequest();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReplacementRequest = createAsyncThunk(
  "shift/getReplacement",
  async (thunkAPI) => {
    try {
      return await shiftService.getReplacementRequest();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const approveReplacementRequest = createAsyncThunk(
  "shift/approveReplacementRequest",
  async (data, thunkAPI) => {
    try {
      return await shiftService.approveReplacementRequest(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteShiftDetail = createAsyncThunk(
  "shift/deleteShiftDetail",
  async (id, thunkAPI) => {
    try {
      return await shiftService.deleteShiftDetail(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getExceedingUser = createAsyncThunk(
  "shift/getExceedingUser",
  async (id, thunkAPI) => {
    try {
      return await shiftService.getExceedingUser();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    resetShift: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.isList = false;
    },
    resetMsg: (state) => {
      state.replcementMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShifts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.message = action.payload.data.message
        state.isList = true;
        state.Shift_List = action.payload.shifts;

      })
      .addCase(getShifts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Shift_List = null;
        state.Shift_List_Export=null
      })
      .addCase(exportShift.fulfilled, (state, action) => {
        // state.message = action.payload.data.message
        state.Shift_List_Export = action.payload.shifts;

      })
      .addCase(getShiftById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.Shift = action.payload.data;
      })
      .addCase(getShiftById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Shift = null;
      })

      .addCase(addShifts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action?.payload?.data?.msg;
        // state.Users_List = [...state.Users_List, action.payload.data]
      })
      .addCase(addShifts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action?.payload;
        state.Shift_List = null;
        state.Shift_List_Export = action.payload.shifts;

      })
      .addCase(assignShift.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignShift.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action?.payload?.data?.msg;
      })
      .addCase(assignShift.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action?.payload;
        state.Shift_List = null;
        state.Shift_List_Export = null;

        
      })
      .addCase(getToBePublishedShifts.fulfilled, (state, action) => {
        state.toBePublishedShifts = [...action.payload.data];
      })
      .addCase(getShiftWithIssues.fulfilled, (state, action) => {
        state.issueShift = action.payload.data;
      })
      .addCase(createReplacement.rejected, (state, action) => {
        state.replcementMessage = action?.payload;
      })
      .addCase(getUsersReplacementRequest.fulfilled, (state, action) => {
        state.usersReplacement = [...action?.payload?.data];
      })
      .addCase(getReplacementRequest.fulfilled, (state, action) => {
        state.replacementRequests = [...action?.payload?.data];
      })
      .addCase(getExceedingUser.fulfilled, (state, action) => {
        state.exceedingUser = action?.payload?.data;
      })
      .addCase(vacantShift.fulfilled, (state, action) => {
        state.vacantShifts = action?.payload?.data;
      });
  },
});

export const { resetShift, resetMsg } = shiftSlice.actions;
export default shiftSlice.reducer;
