import "./search.scss";
import plusBtn from "../../assets/images/plus.svg";
import searchIcon from "../../assets/images/header-search.svg";
import exportIcon from "../../assets/images/export.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../features/common/commonSlice";
import { CSVLink } from "react-csv";

export default function Search(props) {
  const dispatch = useDispatch();
  const { Sites_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.sites);
  const { departments } = useSelector((state) => state.common);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchText, setSearchText] = useState("");
  const handleChange = (e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    props.handleSearch(newSearchText);
  };
  useEffect(() => {
    dispatch(getDepartments());
  }, []);

  const csvData = [
    ["Site Name", "Site Address", "Site Job Positions", "Employess Assigned"],
    ...Sites_List.map(
      ({ name, streetAddress, jobPositions, siteEmployees }) => [
        name,
        streetAddress,
        jobPositions?.length,
        0,
      ]
    ),
  ];
  return (
    <>
      <div className="search-wrapper">
        <div className="search-left-side"></div>
        <div className="search-right-side flex">
          {props.searchFilter === true && (
            <div className="search-filter">
              <span>Filter :</span>
              <select
                onChange={(e) => {
                  props.handleDepartSearch(e.target.value);
                }}
              >
                <option value="AllDepartment">All Department</option>
                {departments?.data?.map((department) => (
                  <option value={department?.name}>{department?.name}</option>
                ))}
              </select>
            </div>
          )}
          <form action="" className="header-search">
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={handleChange}
            />
            <button>
              <img src={searchIcon} alt="searchIcon" />
            </button>
          </form>
          {props.exportBtn === true && (
            <CSVLink filename="sites.csv" data={csvData}>
              <button className="export-btn">
                {/* <input
                  type="file"
                  accept="application/pdf, application/vnd.ms-excel"
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                  }}
                /> */}

                <img src={exportIcon} alt="exportIcon" />
                {selectedFile && <>{selectedFile.name}</>}
                {!selectedFile && <>Export</>}
              </button>
            </CSVLink>
          )}
          <button className="theme-btn" onClick={props.setModalClick}>
            <img src={plusBtn} alt="plusBtn" />
            {props.buttonText}
          </button>
        </div>
      </div>
    </>
  );
}
