import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import { Formik, Form, Field } from "formik";
import InputColor from "react-input-color";
import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addSites, reset } from "../../../features/sites/siteSlice";
import { getCSC } from "../../../features/common/commonSlice";

const initialValues = {
  name: "",
  streetAddress: "",
  streetAddress2: "",
  city: "",
  postalCode: "",
  country: "",
  phone: "",
  jobPositions: [],
};

// Messages
const required = "This field is required";

// Error Component
const errorMessage = (error) => {
  return <div className="invalid-feedback">{error}</div>;
};

// Error Handling
const validateSiteName = (value) => {
  return !value ? required : null;
};
const validateAddress = (value) => {
  return !value ? required : null;
};
const validateCity = (value) => {
  return !value ? required : null;
};
const validatePostal = (value) => {
  return !value ? required : null;
};
const validateCountry = (value) => {
  return !value ? required : null;
};
const validatePosition = (value) => {
  return !value ? required : null;
};

export default function AddNewSite(props) {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { csc } = useSelector((state) => state.common);
  const { Sites_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.sites);
  const [color, setColor] = useState({});
  const [inputList, setInputList] = useState([{ title: "", color: "#5e72e4" }]);
  const [contactNumber, setValue] = useState();
  const [colorMsg, setColorMsg] = useState("");

  // get country state city
  useEffect(() => {
    dispatch(getCSC());
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleInputColorChange = (color, index) => {
    const { hex } = color;
    const list = [...inputList];
    if (hex !== "#5e72e4") {
      setColorMsg("");
    }
    // Check if the color code already exists in the input list
    const isColorExists = list.some((item) => item.color === hex);
    list[index].color = hex;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    const colorSet = new Set();
    const duplicateColors = [];

    for (let i = 0; i < inputList.length; i++) {
      const color = inputList[i].color;

      if (colorSet.has(color)) {
        duplicateColors.push(color);
      } else {
        colorSet.add(color);
      }
    }

    if (duplicateColors.length > 0) {
      setColorMsg("Color selected is in used, please choose another color.");
    }
    setInputList([...inputList, { title: "", color: "#5e72e4" }]);
  };
  useEffect(() => {
    if (isSuccess == true && message !== "") {
      toast.success(message);
      dispatch(reset());
      setTimeout(() => {
        props.setModalClick(false);
      }, 1500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
  }, [message, isSuccess]);
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Add New Site</h2>
              <img onClick={props.setModalClick} src={cross} alt="cross" />
            </div>
            <div className="modal-body">
              <Formik
                initialValues={initialValues}
                onSubmit={(values, action) => {
                  if (typeof contactNumber === "undefined") {
                    toast.error("contactNumber is required!");
                  } else if (typeof values.address2 === "undefined") {
                    toast.error("Street Address Line 2 is required!");
                  } else {
                    let siteData = {
                      name: values.siteName,
                      streetAddress: values.address,
                      streetAddress2: values.address2,
                      city: values.city,
                      postalCode: values.postal,
                      country: values.country,
                      phone: contactNumber,
                      jobPositions: inputList,
                    };
                    const res = dispatch(addSites(siteData));
                  }
                }}
              >
                {({ errors, touched, isValidating }) => (
                  <Form>
                    <div className="add-form">
                      <ul className="list-style-none flex flex-wrap   ">
                        <li>
                          <div className="form-group">
                            <label htmlFor="">
                              Site Name <small>*</small>
                            </label>
                            <Field
                              className="form-control"
                              type="text"
                              maxLength={50}
                              placeholder="Add Site"
                              name="siteName"
                              validate={validateSiteName}
                            />
                            {errors.siteName &&
                              touched.siteName &&
                              errorMessage(errors.siteName)}
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <label htmlFor="">
                              Address <small>*</small>
                            </label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="Street Address"
                              name="address"
                              validate={validateAddress}
                            />
                            {errors.address &&
                              touched.address &&
                              errorMessage(errors.address)}
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="Street Address Line 2"
                              name="address2"
                            />
                            {errors.address &&
                              touched.address &&
                              errorMessage(errors.address)}
                          </div>
                        </li>
                        <li className="width-50">
                          <div className="form-group">
                            <label htmlFor="">City</label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="City"
                              name="city"
                              validate={validateCity}
                            />
                            {errors.city &&
                              touched.city &&
                              errorMessage(errors.city)}
                          </div>
                        </li>
                        <li className="width-50">
                          <div className="form-group">
                            <label htmlFor="">
                              Postal / Zip Code<small>*</small>
                            </label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="Postal / Zip Code"
                              name="postal"
                              validate={validatePostal}
                            />
                            {errors.postal &&
                              touched.postal &&
                              errorMessage(errors.postal)}
                          </div>
                        </li>
                        <li className="width-50">
                          <div className="form-group">
                            <label htmlFor="">Country</label>
                            <Field
                              className="form-control"
                              as="select"
                              name="country"
                              validate={validateCountry}
                            >
                              <option value="">Select</option>
                              {
                                csc?.data?.map((country, index) => (
                                  <option key={index} name={country.name} value={country.country_code}>
                                    {country.name}
                                  </option>
                                ))
                              }
                            </Field>
                            {errors.country &&
                              touched.country &&
                              errorMessage(errors.country)}
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <label htmlFor="">
                              Sites Phone Number <small>*</small>
                            </label>
                            <PhoneInput
                              international
                              defaultCountry="id"
                              value={contactNumber}
                              onChange={setValue}
                            />
                          </div>
                        </li>
                        <li>
                          <h6>Job Positions</h6>
                          <div className="form-group filed-title flex content-justify-between">
                            <label htmlFor="">
                              Job Positions <small>*</small>
                            </label>
                            <label htmlFor="">Color</label>
                          </div>
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <ul
                                  key={`list-${i}`}
                                  className="flex list-style-none position-color"
                                >
                                  <li>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Add Position Name"
                                        name="title"
                                        value={i.title}
                                        // validate={validatePosition}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-group">
                                      <InputColor
                                        initialValue="#5e72e4"
                                        onChange={(color) =>
                                          handleInputColorChange(color, i)
                                        }
                                        placement="right"
                                        name={x.color}
                                        value={x.color}
                                        className="picker-control"
                                      />
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-group">
                                      {inputList.length !== 1 && (
                                        <div
                                          className="crossList form-control"
                                          onClick={() => handleRemoveClick(i)}
                                        >
                                          <img src={cross} alt="cross" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                                {<p className="invalid-feedback">{colorMsg}</p>}
                                {/* {colorMsg && <p>{colorMsg}</p>} */}
                                {/* {errors.positionName && touched.positionName && errorMessage(errors.positionName)} */}
                                {/*{errors.colorSelection && touched.colorSelection && errorMessage(errors.colorSelection)}*/}
                                {inputList.length - 1 === i && (
                                  <button
                                    onClick={handleAddClick}
                                    className="add-position"
                                  >
                                    + Add Job Position
                                  </button>
                                )}
                              </>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                    <div className="flex content-justify-end">
                      <button className="simple-btn" type="submit">
                        Create New Site
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
