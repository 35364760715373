import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Verified from "../../assets/images/verified.svg";
import Dummy from "../../assets/images/dummy-profile.jpg";
import Arrow from "../../assets/images/arrow_right.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getExceedingUser,
  getReplacementRequest,
  getShiftWithIssues,
  getUsersReplacementRequest,
  vacantShift,
} from "../../features/shifts/shiftSlice";
import ShiftReplacements from "./ShiftReplacementCard";
import { format, formatDuration } from "date-fns";
export default function Issues() {
  const issues = useSelector((state) => state.shifts.issueShift);
  const exceedingUser = useSelector((state) => state.shifts.exceedingUser);
  const vacantShifts = useSelector((state) => state.shifts.vacantShifts);
  const user = useSelector((state) => state.auth.user);
  const [issuesObject, setIssuesObject] = useState({});
  const replacementRequest = useSelector(
    (state) => state?.shifts?.replacementRequests
  );
  const usersReplacement = useSelector(
    (state) => state?.shifts?.usersReplacement
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShiftWithIssues());
    dispatch(getExceedingUser());
    if (user.role == "admin") {
      dispatch(getReplacementRequest());
      dispatch(vacantShift());
    } else {
      dispatch(getReplacementRequest());
      dispatch(getUsersReplacementRequest());
    }
  }, []);
  useEffect(() => {
    const object = {};
    issues?.forEach((issue) => {
      if (issue.assignedUser[0]?.email) {
        object[issue.assignedUser[0]?.email] = object[
          issue.assignedUser[0]?.email
        ]
          ? ++object[issue.assignedUser[0]?.email]
          : 1;
      }
    });
    setIssuesObject(object);
  }, [issues, dispatch]);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date("2000-01-01 " + startTime);
    const end = new Date("2000-01-01 " + endTime);
    const duration = end - start;
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    return { hours, minutes };
  };

  const formatDuration = (startTime, endTime) => {
    const { hours, minutes } = calculateDuration(startTime, endTime);
    if ((hours || minutes) < 0) {
      return `0:0H`;
    } else {
      return `${hours}:${minutes}H`;
    }
  };

  const totalIssues = issues?.length - 1 + vacantShifts?.length - 1;

  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Issues ({totalIssues})</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="issues">
          <Tabs
            defaultActiveKey="AllIssues"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="AllIssues" title="All Issues">
              {issues?.length ? (
                <>
                  <h5 className="title">Overlapping Shifts</h5>
                  {issues?.map((issue) => (
                    <div className="mb-2">
                      <div className="time_site">
                        <div className="overlaping_shift">
                          <div className="time_site">
                            <h5>
                              {issue?.issues?.length} Overlapping Shifts on{" "}
                              {format(new Date(issue?.date), "MMMM-dd-yyyy")}
                            </h5>
                            <div className="d-flex align-items-center">
                              <img
                                src={issue.assignedUser.profile}
                                alt="dummy"
                              />
                              <p>{`${issue.assignedUser.firstName} ${issue.assignedUser.lastName}`}</p>
                            </div>
                          </div>
                          <div className="job">
                            <img src={Verified} alt="Verified" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
              <h5 className="title">Shift Replacements</h5>
              {user?.role == "admin" ? (
                <>
                  <small className="mb-2 d-block">Approval Pending</small>
                  {replacementRequest?.map((req) => (
                    <ShiftReplacements
                      position={req.shiftDetailId?.position}
                      date={
                        req?.shiftDetailId &&
                        format(new Date(req?.shiftDetailId?.date), "dd/MM/yyyy")
                      }
                      startTime={req.shiftDetailId?.startTime}
                      endTime={req.shiftDetailId?.endTime}
                      siteName={req.shiftDetailId?.siteName}
                      requester={`${req.userId.firstName} ${req.userId.lastName}`}
                      userToBeReplaced={`${req.userTobeReplaced.firstName} ${req.userTobeReplaced.lastName}`}
                      replacement={req}
                    />
                  ))}
                </>
              ) : (
                <>
                  <small className="mb-2 d-block">Approval Pending</small>
                  {replacementRequest?.map((req) => (
                    <ShiftReplacements
                      position={req.shiftDetailId?.position}
                      date={format(
                        new Date(req.shiftDetailId?.date),
                        "dd/MM/yyyy"
                      )}
                      startTime={req.shiftDetailId?.startTime}
                      endTime={req.shiftDetailId?.endTime}
                      siteName={req.shiftDetailId?.siteName}
                      requester={`${req.userId.firstName} ${req.userId.lastName}`}
                      userToBeReplaced={`${req.userTobeReplaced.firstName} ${req.userTobeReplaced.lastName}`}
                      replacement={req}
                    />
                  ))}
                  <small className="mb-2 d-block">Your Requests</small>
                  {usersReplacement?.map((req) => (
                    <ShiftReplacements
                      disable
                      position={req.shiftDetailId?.position}
                      date={format(
                        new Date(req.shiftDetailId?.date),
                        "dd/MM/yyyy"
                      )}
                      startTime={req.shiftDetailId?.startTime}
                      endTime={req.shiftDetailId?.endTime}
                      siteName={req.shiftDetailId?.siteName}
                      requester={`${req.userId.firstName} ${req.userId.lastName}`}
                      userToBeReplaced={`${req.userTobeReplaced.firstName} ${req.userTobeReplaced.lastName}`}
                      replacement={req}
                    />
                  ))}
                </>
              )}
              <h5 className="title">Exceeding Time</h5>
              {exceedingUser?.length &&
                exceedingUser?.map((user) => (
                  <div className="overlaping_shift">
                    <div className="time_site pb-2">
                      <h5>
                        Exceeding Time {user.totalHours} Hours per Week on{" "}
                        {format(new Date(user._id.week.startDate), "MMMM-dd")} -
                        {format(new Date(user._id.week.endDate), "MMMM-dd")}
                      </h5>
                      <div className="d-flex align-items-center">
                        <img
                          src={user.assignedUser[0].profile || Dummy}
                          alt="dummy"
                        />
                        <p>{`${user.assignedUser[0].firstName} ${user.assignedUser[0].lastName}`}</p>
                      </div>
                    </div>
                    <div className="job">
                      <img src={Verified} alt="Verified" />
                    </div>
                  </div>
                ))}

              <h5 className="title">Vacant Shift</h5>
              {vacantShifts?.map((vacant) => (
                <div className="vacant_shift mb-2">
                  <div className="time_site">
                    <h5>
                      {`${new Date(vacant.startTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}`}
                      -
                      {`${new Date(vacant.endTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}`}{" "}
                      ({" "}
                      {formatDuration(
                        new Date(vacant.startTime).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }),
                        new Date(vacant.endTime).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      )}
                      )
                    </h5>
                    <h5>
                      {vacant.siteName} -{" "}
                      {format(new Date(vacant?.date), "mm-dd-yyyy")}
                    </h5>
                    <p>Job Position haven’t be assigned</p>
                  </div>
                  <div className="job">
                    <h5>{vacant.position}</h5>
                  </div>
                </div>
              ))}
            </Tab>
            <Tab eventKey="VacantShift" title="Vacant Shift">
              <h5 className="title">Vacant Shift</h5>
              {vacantShifts?.map((vacant) => (
                <div className="vacant_shift mb-2">
                  <div className="time_site">
                    <h5>
                      {`${new Date(vacant.startTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}`}
                      -
                      {`${new Date(vacant.endTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}`}{" "}
                      ({" "}
                      {formatDuration(
                        new Date(vacant.startTime).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }),
                        new Date(vacant.endTime).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      )}
                      )
                    </h5>
                    <h5>
                      {vacant.siteName} -{" "}
                      {format(new Date(vacant?.date), "mm-dd-yyyy")}
                    </h5>
                    <p>Job Position haven’t be assigned</p>
                  </div>
                  <div className="job">
                    <h5>{vacant.position}</h5>
                  </div>
                </div>
              ))}
            </Tab>
            <Tab eventKey="OverlappingShifts" title="Overlapping Shifts">
              <h5 className="title">Overlapping Shifts</h5>
              {issues?.length ? (
                <>
                  {issues?.map((issue) => (
                    <div className="mb-2">
                      <div className="time_site">
                        <div className="overlaping_shift">
                          <div className="time_site">
                            <h5>
                              {issue?.issues?.length} Overlapping Shifts on{" "}
                              {format(new Date(issue?.date), "mm-dd-yyyy")}
                            </h5>
                            <div className="d-flex align-items-center">
                              <img
                                src={issue.assignedUser.profile}
                                alt="dummy"
                              />
                              <p>{`${issue.assignedUser.firstName} ${issue.assignedUser.lastName}`}</p>
                            </div>
                          </div>
                          <div className="job">
                            <img src={Verified} alt="Verified" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Tab>
            <Tab eventKey="ShiftReplacements" title="Shift Replacements">
              <h5 className="title">Shift Replacements</h5>
              {user?.role == "admin" ? (
                <>
                  <small className="mb-2 d-block">Approval Pending</small>
                  {replacementRequest?.map((req) => (
                    <ShiftReplacements
                      position={req.shiftDetailId?.position}
                      date={
                        req.shiftDetailId?.date &&
                        format(new Date(req.shiftDetailId?.date), "dd/MM/yyyy")
                      }
                      startTime={req.shiftDetailId?.startTime}
                      endTime={req.shiftDetailId?.endTime}
                      siteName={req.shiftDetailId?.siteName}
                      requester={`${req.userId.firstName} ${req.userId.lastName}`}
                      userToBeReplaced={`${req.userTobeReplaced.firstName} ${req.userTobeReplaced.lastName}`}
                      replacement={req}
                    />
                  ))}
                </>
              ) : (
                <>
                  <small className="mb-2 d-block">Approval Pending</small>
                  {replacementRequest?.map((req) => (
                    <ShiftReplacements
                      position={req.shiftDetailId?.position}
                      date={format(
                        new Date(req.shiftDetailId?.date),
                        "dd/MM/yyyy"
                      )}
                      startTime={req.shiftDetailId?.startTime}
                      endTime={req.shiftDetailId?.endTime}
                      siteName={req.shiftDetailId?.siteName}
                      requester={`${req.userId.firstName} ${req.userId.lastName}`}
                      userToBeReplaced={`${req.userTobeReplaced.firstName} ${req.userTobeReplaced.lastName}`}
                      replacement={req}
                    />
                  ))}
                  <small className="mb-2 d-block">Your Requests</small>
                  {usersReplacement?.map((req) => (
                    <ShiftReplacements
                      disable
                      position={req.shiftDetailId?.position}
                      date={format(
                        new Date(req.shiftDetailId?.date),
                        "dd/MM/yyyy"
                      )}
                      startTime={req.shiftDetailId?.startTime}
                      endTime={req.shiftDetailId?.endTime}
                      siteName={req.shiftDetailId?.siteName}
                      requester={`${req.userId.firstName} ${req.userId.lastName}`}
                      userToBeReplaced={`${req.userTobeReplaced.firstName} ${req.userTobeReplaced.lastName}`}
                      replacement={req}
                    />
                  ))}
                </>
              )}
            </Tab>
            <Tab eventKey="Exceeding Time" title="Exceeding Time">
              <h5 className="title">Exceeding Time</h5>
              {exceedingUser?.length &&
                exceedingUser?.map((user) => (
                  <div className="overlaping_shift">
                    <div className="time_site pb-2">
                      <h5>
                        Exceeding Time {user.totalHours} Hours per Week on{" "}
                        {format(new Date(user._id.week.startDate), "MMMM-dd")} -
                        {format(new Date(user._id.week.endDate), "MMMM-dd")}
                      </h5>
                      <div className="d-flex align-items-center">
                        <img
                          src={user.assignedUser[0].profile || Dummy}
                          alt="dummy"
                        />
                        <p>{`${user.assignedUser[0].firstName} ${user.assignedUser[0].lastName}`}</p>
                      </div>
                    </div>
                    <div className="job">
                      <img src={Verified} alt="Verified" />
                    </div>
                  </div>
                ))}
            </Tab>
          </Tabs>
        </div>
      </Offcanvas.Body>
    </>
  );
}
