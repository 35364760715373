import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import upload_clr from "../../../assets/images/upload_clr.svg";
import Form from "react-bootstrap/Form";
import "../modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import TimePicker from "react-time-picker";
import { useState } from "react";
import { toast } from "react-toastify";
import { createTimeOff } from "../../../features/timeOff/timeOffSlice";
import CustomCheckBoxes from "../../customCheckboxes/CustomCheckBoxes";
import IncrementDecrement from "../../incrementDecrement/IncrementDecrement";
import { saveImage } from "../../../features/MyProfile/profileSlice";

export default function AddTimeOffModal(props) {
  const user_list = useSelector((state) => state?.users?.Users_List);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("18:00");
  const [repeatCondition, setrepeatCondition] = useState(0);
  const [attachement, setAttachement] = useState("");
  const [weeklyRepeatCondition, setWeeklyRepeatCondition] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });
  const [values, setValues] = useState({
    user: user.role == "admin" ? "" : user._id,
    type: "sickLeave",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    allDay: false,
    note: "",
  });
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>
                {user.role == "admin" ? "Add Time Off" : "Request Time Off"}
              </h2>
              <img onClick={props.setModalClick} src={cross} alt="cross" />
            </div>
            <div className="modal-body">
              <div className="add_timeoff_modal">
                <Formik
                  initialValues={values}
                  onSubmit={async (values) => {
                    let startDate = values.startDate;
                    let endDate = values.endDate;
                    if (!values.startDate || !values.endDate || !values.user) {
                      toast.error("Please input all required fields!");
                      return;
                    }
                    if (values.allDay) {
                      startDate = new Date(`${startDate}T09:00`);
                      endDate = new Date(`${endDate}T18:00`);
                    } else {
                      startDate = new Date(`${startDate}T${startTime}`);
                      endDate = new Date(`${endDate}T${endTime}`);
                    }
                    await dispatch(
                      createTimeOff({
                        ...values,
                        repeatCondition:
                          values.repeat == "weekly"
                            ? weeklyRepeatCondition
                            : repeatCondition,
                        startDate: startDate,
                        endDate: endDate,
                        note: attachement,
                      })
                    );
                    props.setModalClick();
                  }}
                >
                  {({ values, handleSubmit, setFieldValue }) => (
                    <form action="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>
                              Employess <small>*</small>
                            </label>
                            {user.role == "admin" ? (
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  setFieldValue("user", e.target.value)
                                }
                              >
                                {user_list.map((user) => (
                                  <option
                                    value={user._id}
                                  >{`${user.firstName} ${user.lastName}`}</option>
                                ))}
                              </select>
                            ) : (
                              <>
                                <input
                                  disabled
                                  type="text"
                                  value={`${user.firstName} ${user.lastName}`}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <label>
                              Time Off Type <small>*</small>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setFieldValue("type", e.target.value)
                              }
                            >
                              <option value="sickLeave">Sick Leave</option>
                              <option value="timeOff">Time Off</option>
                            </select>
                          </div>
                        </div>
                        <h6 className="mb-3">Time</h6>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label>
                              Start Date <small>*</small>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) =>
                                setFieldValue("startDate", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label>
                              End Date <small>*</small>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) =>
                                setFieldValue("endDate", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb-3">
                            <label>All Day</label>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              onChange={(e) =>
                                setFieldValue("allDay", !values.allDay)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label>
                              Start Time <small>*</small>
                            </label>
                            <TimePicker
                              name="startTime"
                              onChange={setStartTime}
                              value={startTime}
                              className="form-control"
                              format="h:mm a"
                              disableClock
                              showLeadingZeros
                              required
                              clockIcon={null}
                              clearIcon={null}
                              disabled={values.allDay}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label>
                              End Time <small>*</small>
                            </label>
                            <TimePicker
                              name="endTime"
                              onChange={setEndTime}
                              value={endTime}
                              className="form-control"
                              format="h:mm a"
                              disableClock
                              showLeadingZeros
                              required
                              clockIcon={null}
                              clearIcon={null}
                              disabled={values.allDay}
                            />
                          </div>
                        </div>
                        <div className="row align-items-end pb-3">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">Repeat</label>
                              <div>
                                <select
                                  name="repeat"
                                  onChange={(e) =>
                                    setFieldValue("repeat", e.target.value)
                                  }
                                  className="form-control"
                                >
                                  <option value="Does Not Repeat">
                                    Does Not Repeat
                                  </option>
                                  <option value="daily">Daily</option>
                                  <option value="weekly">Weekly</option>
                                  <option value="monthly">Monthly</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            {values.repeat == "weekly" ? (
                              <CustomCheckBoxes
                                value={weeklyRepeatCondition}
                                setValue={setWeeklyRepeatCondition}
                              />
                            ) : (
                              <IncrementDecrement
                                value={repeatCondition}
                                setValue={setrepeatCondition}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <p>
                            Attachments (Max upload the image size: 2MB,
                            Supported files: .jpg, .png, .gif)
                          </p>
                          <div className="card">
                            <div className="upload-btn-wrapper position-relative">
                              <img className="upload" src={upload_clr} /> Upload
                              Attachments
                              <input
                                type="file"
                                name="avatar"
                                accept=".jpg,.gif,.png"
                                className="position-absolute h-100"
                                style={{
                                  cursor: "pointer",
                                }}
                                onChange={async (e) => {
                                  const file = e.target.files[0];
                                  const bodyFormData = new FormData();
                                  bodyFormData.append("image", file);
                                  const { payload } = await dispatch(
                                    saveImage(bodyFormData)
                                  );
                                  setAttachement(payload.data);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h6>Total Time Off Hours : 1 Days (8 Work Hours)</h6>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            className="form-control"
                            placeholder="Add a note to your response (optional)"
                          ></textarea>
                        </div>
                        <div className="col-md-12">
                          <button
                            className="simple-btn ml-auto"
                            type="button"
                            onClick={handleSubmit}
                          >
                            {user.role == "admin"
                              ? "Add Time Off"
                              : "Request Time Off"}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
