import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ErrorMessage, Form, Formik } from "formik";
import { createShiftValidation } from "../../validations/shift.validation";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import cross from "../../assets/images/close.svg";
import { convertDraftToHTML } from "../../../src/utils/HTML";
import { getSites } from "../../features/sites/siteSlice";
import "react-toastify/dist/ReactToastify.css";
import { resetShift, addShifts } from "../../features/shifts/shiftSlice";
import TimePicker from "react-time-picker";
import CustomCheckBoxes from "../customCheckboxes/CustomCheckBoxes";
import IncrementDecrement from "../incrementDecrement/IncrementDecrement";

export default function AddNewShift(props) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [seletectedSiteId, setSeletectedSiteId] = useState(null);
  const [counter, setCounter] = useState(0);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [repeatCondition, setrepeatCondition] = useState(0);
  const [weeklyRepeatCondition, setWeeklyRepeatCondition] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });
  const [inputList, setInputList] = useState([
    {
      title: "",
      headCount: 0,
    },
  ]);
  const [endTime, setEndTime] = useState("09:00 AM");
  const [startTime, setStartTime] = useState("09:00 AM");
  const [checkError, setCheckError] = useState(false);
  const { Shift_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.shifts);
  const site = useSelector((state) => state.sites);
  const { Sites_List } = useSelector((state) => state.sites);
  const [shiftFormData, setShiftFormData] = useState({
    tital: "",
    description: "",
    date: "",
    allDay: false,
    startTime: "",
    endTime: "",
    repeat: "",
    endRepeat: "",
    site: "",
    jobPositions: "",
    headCount: counter,
  });
  const incrementCounter = (index) => {
    setInputList((prevList) => {
      const newList = [...prevList];
      if (newList[index]) {
        newList[index].headCount += 1;
      }
      return newList;
    });
  };
  const decrementCounter = (index) => {
    setInputList((prevList) => {
      const newList = [...prevList];
      if (newList[index] && newList[index].headCount > 0) {
        newList[index].headCount -= 1;
      }
      return newList;
    });
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { title: "", headCount: 0 }]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setInputList((prevList) => {
      const newList = [...prevList];
      newList[index][name] = value;
      return newList;
    });
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...shiftFormData };
    newFormData[fieldName] = fieldValue;
    setShiftFormData(newFormData);
  };
  const handleDayFormChange = (event) => {
    const isChecked = event.target.checked;

    setShiftFormData((prevFormData) => ({
      ...prevFormData,
      allDay: isChecked,
    }));
  };
  const calculateDuration = () => {
    const start = new Date("2000-01-01 " + startTime);
    const end = new Date("2000-01-01 " + endTime);
    const duration = end - start;

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return { hours, minutes };
  };

  const formatDuration = () => {
    const { hours, minutes } = calculateDuration();
    if ((hours || minutes) < 0) {
      return `0 hours 0 minutes`;
    } else {
      return `${hours} hours ${minutes} minutes`;
    }
  };

  useEffect(() => {
    dispatch(getSites());
  }, [checkError]);
  useEffect(() => {
    if (isSuccess == true && message !== "") {
      toast.success(message);
      dispatch(resetShift());
      setTimeout(() => {
        props.handleClose();
      }, 1500);
    }
    if (isError == true && message !== "") {
      setCheckError(true);
      toast.error(message);
      dispatch(resetShift());
    }
  }, [isSuccess, isError]);
  return (
    <>
      <Offcanvas.Header closeButton onHide={props.handleClose}>
        <Offcanvas.Title>Add New Shift</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          validationSchema={createShiftValidation}
          initialValues={{
            tital: "",
            description: "",
            date: "",
            allDay: "",
            startTime: "",
            endTime: "",
            repeat: { frequency: "", endRepeat: "" },
            site: "",
            jobPositions: "",
            headCount: "",
          }}
          onSubmit={async (values) => {
            const shiftData = {
              title: values?.name,
              description: convertDraftToHTML(values?.description)?.__html,
              date: values?.date,
              allDay: shiftFormData?.allDay,
              startTime: startTime,
              endTime: endTime,
              repeat: {
                frequency: shiftFormData?.repeat,
                endRepeat: shiftFormData?.endRepeat,
              },
              site: shiftFormData?.site,
              jobPositions: inputList ? inputList : [],
              repeatCondition:
                shiftFormData.repeat == "weekly"
                  ? weeklyRepeatCondition
                  : repeatCondition,
            };
            try {
              const res = dispatch(addShifts(shiftData));
            } catch (error) {
              toast.error("Unable to create Shift, please try again!");
            }
          }}
        >
          {({ errors, setFieldValue, values, submitForm, touched }) => (
            <Form>
              <div className="add-form">
                <p className="detail">Shift Details</p>
                <ul className="list-style-none flex flex-wrap">
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Shift Title <small>*</small>
                      </label>
                      <input
                        style={
                          errors.name &&
                          touched.name && {
                            borderColor: "red",
                          }
                        }
                        className="form-control"
                        type="text"
                        placeholder="Add Title"
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        value={values.name}
                      />
                      <ErrorMessage name="name" component={"div"} />
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Shift Descriptions{""}
                        <small className="optional">(Optional)</small>
                      </label>
                      <div className="editor-toolbar editor-color">
                        <Editor
                          editorState={values?.description}
                          onEditorStateChange={(value) =>
                            setFieldValue("description", value)
                          }
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          toolbar={{
                            options: ["inline", "list"],
                          }}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <p className="detail mb-0">Time</p>
                  </li>
                  <li>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">
                            Date <small>*</small>
                          </label>
                          <input
                            type="date"
                            className="form-control date"
                            onChange={(e) =>
                              setFieldValue("date", e.target.value)
                            }
                            value={values.date}
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="form-group">
                          <label htmlFor="">All Day</label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="allDay"
                              role="switch"
                              onChange={handleDayFormChange}
                              value={values.allDay}
                              id="flexSwitchCheckChecked"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <div className="form-group">
                          <label htmlFor="">
                            Start Time <small>*</small>
                          </label>
                          <TimePicker
                            name="startTime"
                            onChange={setStartTime}
                            value={startTime}
                            className="form-control"
                            style={{ maxWidth: "145px" }}
                            format="h:m a"
                            clockIcon={null}
                            clearIcon={null}
                            disableClock
                            showLeadingZeros
                            required
                          />
                        </div>
                      </div>
                      <div className="me-3">
                        <div className="form-group">
                          <label htmlFor="">
                            End Time <small>*</small>
                          </label>
                          <TimePicker
                            name="endTime"
                            onChange={setEndTime}
                            value={endTime}
                            className="form-control"
                            style={{ Width: "145px" }}
                            format="h:m a"
                            clockIcon={null}
                            clearIcon={null}
                            disableClock
                            showLeadingZeros
                            required
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="detail mb-0 mt-4">{formatDuration()}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row align-items-end">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">Repeat</label>
                          <div>
                            <select
                              name="repeat"
                              onChange={handleAddFormChange}
                              className="form-control"
                            >
                              <option value="Does Not Repeat">
                                Does Not Repeat
                              </option>
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        {shiftFormData.repeat == "weekly" ? (
                          <CustomCheckBoxes
                            value={weeklyRepeatCondition}
                            setValue={setWeeklyRepeatCondition}
                          />
                        ) : (
                          <IncrementDecrement
                            value={repeatCondition}
                            setValue={setrepeatCondition}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">
                            End Repeat <small>*</small>
                          </label>
                          <input
                            type="date"
                            name="endRepeat"
                            onChange={handleAddFormChange}
                            className="form-control date"
                          />
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>
                  </li>
                  <li>
                    <p className="detail mb-0">Assign to</p>
                  </li>
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Sites <small>*</small>
                      </label>
                      <select
                        name="site"
                        onChange={(event) => {
                          setSeletectedSiteId(event.target.value);
                          handleAddFormChange(event);
                        }}
                        className="form-control"
                      >
                        <option value="">Select Site</option>
                        {Sites_List?.map((site) => (
                          <option value={site._id}>{site.name}</option>
                        ))}
                      </select>
                    </div>
                  </li>
                  <li>
                    {inputList?.map((x, i) => {
                      return (
                        <>
                          <div className="row align-items-end mb-3">
                            <div className="col-md-5">
                              <div className="form-group">
                                <label htmlFor="">Job Positions</label>
                                <select
                                  name="title"
                                  onChange={(e) => handleInputChange(e, i)}
                                  className="form-control"
                                >
                                  <option value="does not repeat">
                                    Select Job Position
                                  </option>
                                  {Sites_List.filter(
                                    (o) => o._id == seletectedSiteId
                                  )[0]?.jobPositions.map((job) => (
                                    <option value={job.title}>
                                      {job.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <label htmlFor="">Headcount</label>
                                <div className="counter form-control">
                                  <button
                                    type="button"
                                    onClick={() => decrementCounter(i)}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    value={inputList[i].headCount}
                                    name="headCount"
                                    onChange={(event) =>
                                      handleInputChange(event, i)
                                    }
                                  />
                                  <button
                                    type="button"
                                    onClick={() => incrementCounter(i)}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                {inputList.length !== 1 && (
                                  <div
                                    className="crossList form-control"
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    <img src={cross} alt="cross" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {inputList.length - 1 === i && (
                            <button
                              onClick={handleAddClick}
                              className="add-position"
                            >
                              + Add Job Positions
                            </button>
                          )}
                        </>
                      );
                    })}
                    {/* */}
                  </li>
                </ul>
              </div>
              <div className="flex content-justify-end">
                <button type="submit" className="simple-btn">
                  Add Shift
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </>
  );
}
