import React, { useState } from "react";
import "./IncrementDecrement.scss";

export default function IncrementDecrement({ value, setValue }) {
  const incrementCounter = () => {
    setValue(value + 1);
  };
  const decrementCounter = () => {
    if (value !== 0) {
      setValue(value - 1);
    }
  };
  return (
    <div className="form-group">
      <div className="counter form-control">
        <button type="button" onClick={() => decrementCounter()}>
          -
        </button>
        <input
          type="number"
          name="headCount"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <button type="button" onClick={() => incrementCounter()}>
          +
        </button>
      </div>
    </div>
  );
}
