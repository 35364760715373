import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ErrorMessage, Form, Formik } from "formik";
import { createShiftValidation } from "../../../validations/shift.validation";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import trash from "../../../assets/images/bin.png";
import { convertDraftToHTML } from "../../../../src/utils/HTML";
import { getSites } from "../../../features/sites/siteSlice";
import "react-toastify/dist/ReactToastify.css";
import { convertToRaw, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import {
  resetShift,
  assignShift,
  getShiftById,
  deleteShiftDetail,
} from "../../../features/shifts/shiftSlice";
import TimePicker from "react-time-picker";
import { getUsers, resetUser } from "../../../features/users/userSlice";
import CustomCheckBoxes from "../../customCheckboxes/CustomCheckBoxes";
import IncrementDecrement from "../../incrementDecrement/IncrementDecrement";

export default function AssignShift(props) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [selectedUser, setSelectedUser] = useState(props?.assignedUser || "");
  const [inputList, setInputList] = useState([
    {
      title: "",
      headCount: 0,
    },
  ]);
  const [endTime, setEndTime] = useState("09:00 AM");
  const [startTime, setStartTime] = useState("09:00 AM");
  const [checkError, setCheckError] = useState(false);
  const { Shift, Shift_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.shifts);

  const { user } = useSelector((state) => state.auth);
  const { Users_List } = useSelector((state) => state.users);
  const [shiftFormData, setShiftFormData] = useState({
    tital: "",
    description: "",
    date: "",
    allDay: false,
    startTime: "",
    endTime: "",
    repeat: "",
    endRepeat: "",
    site: "",
    jobPositions: "",
    headCount: counter,
  });
  const incrementCounter = (index) => {
    setInputList((prevList) => {
      const newList = [...prevList];
      if (newList[index]) {
        newList[index].headCount += 1;
      }
      return newList;
    });
  };
  const getEditorState = () => {
    if (Shift?.shift?.description) {
      const contentState = stateFromHTML(Shift?.shift?.description);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...shiftFormData };
    newFormData[fieldName] = fieldValue;
    setShiftFormData(newFormData);
  };
  const calculateDuration = () => {
    const start = new Date("2000-01-01 " + Shift?.shift?.startTime);
    const end = new Date("2000-01-01 " + Shift?.shift?.endTime);
    const duration = end - start;

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return { hours, minutes };
  };

  const formatDuration = () => {
    const { hours, minutes } = calculateDuration();
    if ((hours || minutes) < 0) {
      return `0 hours 0 minutes`;
    } else {
      return `${hours} hours ${minutes} minutes`;
    }
  };
  const handleSubmit = async () => {
    const isReasign = props?.shift?.isReasign ? { isReasign: true } : {};
    const assigShiftData = {
      _id: props?.shift?._id,
      shiftId: props?.shift?.shiftId,
      position: props?.shift?.position,
      date: props?.shift?.date,
      startTime: props?.shift?.startTime,
      endTime: props?.shift?.endTime,
      assignedUser: selectedUser,
      ...isReasign,
    };
    const result = await dispatch(assignShift(assigShiftData));
  };
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getShiftById(props?.shift?.shiftId));
  }, [checkError]);
  useEffect(() => {
    if (isSuccess == true && message !== "") {
      toast.success(message);
      // dispatch(resetShift());
      setTimeout(() => {
        props.closeModal();
      }, 1500);
    }
    if (isError == true && message !== "") {
      setCheckError(true);
      toast.error(message);
      dispatch(resetShift());
    }
  }, [isSuccess, isError]);
  return (
    <>
      <Offcanvas.Header closeButton onHide={props.closeModal}>
        <Offcanvas.Title>
          {props?.shift?.isReasign || !props?.shift?.assignedUser.length
            ? "Shift Assign"
            : "Details"}

          {user?.role == "admin" && (
            <>
              <img
                src={trash}
                className="bin"
                alt="trash"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  await dispatch(deleteShiftDetail(props?.shift?._id));
                  props.closeModal();
                }}
              />
            </>
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik>
          {({ errors, setFieldValue, values, submitForm, touched }) => (
            <Form>
              <div className="add-form">
                <p className="detail">Assign to</p>
                <ul className="list-style-none flex flex-wrap">
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Sites <small>*</small>
                      </label>
                      <select
                        name="site"
                        onChange={handleAddFormChange}
                        className="form-control"
                        disabled
                      >
                        {
                          <option value={Shift?.sites?._id}>
                            {Shift?.sites?.name}
                          </option>
                        }
                      </select>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Job Positions <small>*</small>
                      </label>
                      <select
                        name="site"
                        onChange={handleAddFormChange}
                        className="form-control"
                        disabled
                      >
                        {Shift?.shift?.jobPositions &&
                        Shift?.shift?.jobPositions[0] ? (
                          <option value={Shift?.shift?.jobPositions[0]?.title}>
                            {Shift?.shift?.jobPositions[0]?.title}
                          </option>
                        ) : (
                          <option value="">No job positions available</option>
                        )}
                      </select>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Employees <small>*</small>
                      </label>
                      <select
                        disabled={
                          !Boolean(
                            props?.shift?.isReasign ||
                              !props?.shift?.assignedUser.length
                          )
                        }
                        name="site"
                        onChange={(e) => {
                          setSelectedUser(e.target.value);
                        }}
                        className="form-control"
                      >
                        {!(
                          props?.shift?.isReasign &&
                          props?.shift?.assignedUser.length
                        ) && (
                          <option>
                            {
                              Users_List.filter(
                                (o) =>
                                  o.userId ==
                                  props?.shift?.assignedUser[0]?.userId
                              )[0]?.firstName
                            }
                          </option>
                        )}
                        {Users_List?.map((user) => (
                          <option value={user._id}>{user.firstName}</option>
                        ))}
                      </select>
                    </div>
                  </li>
                  <li>
                    <p className="detail mb-0">Shift Details</p>
                  </li>
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Shift Title <small>*</small>
                      </label>
                      <select
                        name="site"
                        onChange={handleAddFormChange}
                        className="form-control"
                        disabled
                      >
                        {
                          <option value={Shift?.shift?.title}>
                            {Shift?.shift?.title}
                          </option>
                        }
                      </select>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label htmlFor="">
                        Shift Descriptions
                        <small className="optional">(Optional)</small>
                      </label>
                      <div className="editor-toolbar editor-color">
                        <Editor
                          editorState={getEditorState()}
                          readOnly
                          onEditorStateChange={(value) =>
                            setFieldValue("description", value)
                          }
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          toolbar={{
                            options: ["inline", "list"],
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <p className="detail mb-0">Time</p>
                  </li>
                  <li>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">
                            Date <small>*</small>
                          </label>

                          <input
                            type="date"
                            className="form-control date"
                            value={
                              Shift?.shift?.date
                                ? props?.shift?.startTime.split("T")[0]
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="form-group">
                          <label htmlFor="">All Day</label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="allDay"
                              role="switch"
                              // onChange={handleDayFormChange}
                              checked={Shift?.shift?.allDay}
                              value={Shift?.shift?.allDay}
                              id="flexSwitchCheckChecked"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row align-items-end">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">
                            Start Time <small>*</small>
                          </label>
                          <TimePicker
                            name="startTime"
                            onChange={setStartTime}
                            value={Shift?.shift?.startTime}
                            className="form-control"
                            format="h:mm a"
                            clockIcon={null}
                            clearIcon={null}
                            disableClock
                            showLeadingZeros
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">
                            End Time <small>*</small>
                          </label>
                          <TimePicker
                            name="endTime"
                            onChange={setEndTime}
                            value={Shift?.shift?.endTime}
                            className="form-control"
                            format="h:mm a"
                            clockIcon={null}
                            clearIcon={null}
                            disableClock
                            showLeadingZeros
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <p className="detail mb-0">{formatDuration()}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row align-items-end">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">Repeat</label>
                          <select
                            name="repeat"
                            onChange={handleAddFormChange}
                            className="form-control"
                            disabled
                          >
                            <option
                              value={
                                Shift?.shift?.repeat?.frequency
                                  ? Shift?.shift?.repeat?.frequency
                                  : ""
                              }
                            >
                              {Shift?.shift?.repeat?.frequency
                                ? Shift?.shift?.repeat?.frequency
                                : ""}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-7">
                        {Shift?.shift?.repeatCondition?.frequency ==
                        "weekly" ? (
                          <CustomCheckBoxes
                            value={
                              Shift?.shift?.repeatCondition?.repeatCondition
                            }
                            setValue={() => null}
                            flag={true}
                          />
                        ) : (
                          <IncrementDecrement
                            value={
                              Shift?.shift?.repeatCondition?.repeatCondition
                            }
                            setValue={() => null}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label htmlFor="">
                            End Repeat <small>*</small>
                          </label>
                          <input
                            type="date"
                            name="endRepeat"
                            onChange={handleAddFormChange}
                            className="form-control date"
                            value={
                              Shift?.shift?.repeat?.endRepeat
                                ? Shift?.shift?.repeat?.endRepeat.split("T")[0]
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="flex content-justify-end">
                {(props?.shift?.isReasign ||
                  !props?.shift?.assignedUser.length) && (
                  <button
                    type="button"
                    className="simple-btn"
                    onClick={() => handleSubmit()}
                  >
                    Assign Shift
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </>
  );
}
