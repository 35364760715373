import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import siteService from './siteService'

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  Sites_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  isList: false,
}

// get contributer list
export const getSites = createAsyncThunk(
  'site/getSites',
  async (thunkAPI) => {
    try {
      return await siteService.siteList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addSites = createAsyncThunk(
  'site/addSites',
  async (siteData,thunkAPI) => {
    try {
      return await siteService.addSitesData(siteData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editSites = createAsyncThunk(
  'site/editSites',
  async (userData,thunkAPI) => {
    try {
      return await siteService.editSitesData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteSites = createAsyncThunk(
  'site/deleteSites',
  async (ID,thunkAPI) => {
    try {
      return await siteService.deleteSitesData(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.isList = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSites.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        // state.message = action.payload.data.message
        state.isList = true
        state.Sites_List = action?.payload?.sites 
      })
      .addCase(getSites.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        // state.Sites_List = null
      })

      .addCase(addSites.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addSites.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.msg
        // state.Users_List = [...state.Users_List, action.payload.data] 
      })
      .addCase(addSites.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = "Job Position Color is required"
        // state.Sites_List = null
      })

      .addCase(editSites.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editSites.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.Users_List = action.payload.data 
      })
      .addCase(editSites.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.Sites_List = null
      })

      .addCase(deleteSites.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteSites.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.Users_List = action.payload.data 
      })
      .addCase(deleteSites.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.Sites_List = null
      })
  },
})

export const { reset } = siteSlice.actions
export default siteSlice.reducer