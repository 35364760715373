import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import Subtract from "../../../assets/images/Subtract.svg";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { approveTimeOff } from "../../../features/timeOff/timeOffSlice";
export default function RejectTimeOffModal(props) {
  const [rejectionMessage, setRejectionMessage] = useState("");
  const dispatch = useDispatch();
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Reject Time Off</h2>
              <img onClick={props.setModalClick} src={cross} alt="cross" />
            </div>
            <div className="modal-body">
              <div className="reject_timeoff_modal">
                <form action="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Add a note to your response</label>
                        <textarea
                          value={rejectionMessage}
                          onChange={(e) => setRejectionMessage(e.target.value)}
                          className="form-control"
                          cols="30"
                          rows="40"
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex content-justify-end mt-3">
                      <button
                        className="simple-btn reject-btn me-2"
                        type="button"
                        onClick={async () => {
                          await dispatch(
                            approveTimeOff({
                              id: props.selectedRequest,
                              approval: false,
                              rejectionMessage,
                            })
                          );
                          props.setModalClick();
                        }}
                      >
                        <img className="me-2" src={Subtract} alt="Subtract" />{" "}
                        Reject Time Off
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
