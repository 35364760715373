import React from "react";
import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import Dummy from "../../../assets/images/dummy-profile.jpg";
import Request from "../../../assets/images/request.svg";
import Subtract from "../../../assets/images/Subtract.svg";
import SubtractTick from "../../../assets/images/SubtractTick.svg";
import Pending from "../../../assets/images/pending.svg";
import Reject from "../../../assets/images/reject.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  approveReplacementRequest,
  getReplacementRequest,
  getUsersReplacementRequest,
} from "../../../features/shifts/shiftSlice";

export default function PendingApproval({
  setModalClick,
  siteName,
  date,
  requester,
  userToBeReplaced,
  position,
  startTime,
  endTime,
  replacement,
}) {
  const calculateDuration = (startTime, endTime) => {
    const start = new Date("2000-01-01 " + startTime);
    const end = new Date("2000-01-01 " + endTime);
    const duration = end - start;

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return { hours, minutes };
  };

  const formatDuration = (startTime, endTime) => {
    const { hours, minutes } = calculateDuration(startTime, endTime);
    if ((hours || minutes) < 0) {
      return `0:0H`;
    } else {
      return `${hours}:${minutes}H`;
    }
  };
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Replacement Request</h2>
              <img onClick={setModalClick} src={cross} alt="cross" />
            </div>
            <div className="modal-body">
              <div className="approvalModal">
                <p>
                  {requester} asked {userToBeReplaced} for a replacement.
                </p>
                <div className="vacant_shift">
                  <div className="time_site">
                    <h5>Weekday Shift</h5>
                    <h5>
                      {`${new Date(startTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}`}
                      -
                      {`${new Date(endTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}`}{" "}
                      (
                      {formatDuration(
                        new Date(startTime).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }),
                        new Date(endTime).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      )}{" "}
                      )
                    </h5>
                    <h5>
                      {siteName} - {date}
                    </h5>
                  </div>
                  <div className="job">
                    <h5>{position}</h5>
                  </div>
                </div>
                <div className="replacement">
                  <div className="user">
                    <img className="dummy" src={Dummy} alt="dummy" />
                    <span>
                      <img src={Request} alt="request" />
                    </span>
                  </div>
                  <div className="user">
                    <img className="dummy" src={Dummy} alt="dummy" />
                    <span>
                      <img
                        src={
                          replacement?.userApproval == null
                            ? Pending
                            : replacement?.userApproval
                            ? Request
                            : Reject
                        }
                        alt="Pending"
                      />
                    </span>
                  </div>
                  <div className="user">
                    <img className="dummy" src={Dummy} alt="dummy" />
                    <span>
                      <img
                        src={
                          replacement?.adminApproval == null
                            ? Pending
                            : replacement?.adminApproval
                            ? Request
                            : Reject
                        }
                        alt="Reject"
                      />
                    </span>
                  </div>
                </div>
                {user.role == "admin" && replacement.adminApproval == null ? (
                  <p>Shall we approve this request ?</p>
                ) : (
                  replacement.adminApproval == null && (
                    <p>Shall we approve this request ?</p>
                  )
                )}
              </div>

              {user.role == "admin" && replacement.adminApproval == null ? (
                <div className="flex content-justify-end mt-5">
                  <button
                    className="simple-btn reject-btn me-2"
                    type="submit"
                    onClick={async () => {
                      await dispatch(
                        approveReplacementRequest({
                          replacementRequestId: replacement._id,
                          status: false,
                          shiftDetailId: replacement.shiftDetailId._id,
                        })
                      );
                      await dispatch(getReplacementRequest());
                      await dispatch(getUsersReplacementRequest());
                      setModalClick();
                    }}
                  >
                    <img className="me-2" src={Subtract} alt="Subtract" />{" "}
                    Reject Shift
                  </button>
                  <button
                    className="simple-btn simple-btn-hover"
                    type="submit"
                    onClick={async () => {
                      await dispatch(
                        approveReplacementRequest({
                          replacementRequestId: replacement._id,
                          status: true,
                          shiftDetailId: replacement.shiftDetailId._id,
                        })
                      );
                      await dispatch(getReplacementRequest());
                      await dispatch(getUsersReplacementRequest());
                      setModalClick();
                    }}
                  >
                    <img
                      className="me-2"
                      src={SubtractTick}
                      alt="SubtractTick"
                    />
                    Accept Shift
                  </button>
                </div>
              ) : (
                replacement.userApproval == null && (
                  <div className="flex content-justify-end mt-5">
                    <button
                      className="simple-btn reject-btn me-2"
                      type="submit"
                      onClick={async () => {
                        await dispatch(
                          approveReplacementRequest({
                            replacementRequestId: replacement._id,
                            status: false,
                            shiftDetailId: replacement.shiftDetailId._id,
                          })
                        );
                        await dispatch(getReplacementRequest());
                        await dispatch(getUsersReplacementRequest());
                        setModalClick();
                      }}
                    >
                      <img className="me-2" src={Subtract} alt="Subtract" />{" "}
                      Reject Shift
                    </button>
                    <button
                      className="simple-btn simple-btn-hover"
                      type="submit"
                      onClick={async () => {
                        await dispatch(
                          approveReplacementRequest({
                            replacementRequestId: replacement._id,
                            status: true,
                            shiftDetailId: replacement.shiftDetailId._id,
                          })
                        );
                        await dispatch(getReplacementRequest());
                        await dispatch(getUsersReplacementRequest());
                        setModalClick();
                      }}
                    >
                      <img
                        className="me-2"
                        src={SubtractTick}
                        alt="SubtractTick"
                      />
                      Accept Shift
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
