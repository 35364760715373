import "./userManagements.scss";
import Search from "../../components/search/Search";
import AddUser from "../../components/modals/add-user/AddUser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, resetUser } from "../../features/users/userSlice";
import DataTable from "react-data-table-component";

export default function UserManagement() {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [departSearch, handleDepartSearch] = useState("AllDepartment");
  const { Users_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.users);
  const [userList, setUserList] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      name: <div className="table-content">Name</div>,
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: <div className="table-content">Department</div>,
      selector: (row) => row.department,
    },
    {
      name: <div className="table-content">Email</div>,
      selector: (row) => row.email,
    },
    {
      name: <div className="table-content">Phone Number</div>,
      selector: (row) => row.phone,
      // width: '220px'
    },
    {
      name: <div className="table-content">Username</div>,
      selector: (row) => row.username,
      // width: '220px'
    },
    {
      name: <div className="table-content">Role</div>,
      selector: (row) => row.role,
      // width: '220px'
    },
  ];
  const handleSearch = (searchText) => {
    setSearchQuery(searchText);
  };
  const makeRow = (userArr) => {
    var arr =
      Array.isArray(userArr) && userArr.length > 0
        ? userArr.map((data, id) => ({
            name: (
              <figure
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedUser(data);
                  setOpenAddUser(!openAddUser);
                }}
              >
                <img src={data?.profile} />
                <span>{data?.firstName}</span>
              </figure>
            ),
            department: <>{data?.department}</>,
            email: <>{data?.email}</>,
            phone: <>{data?.contactNo}</>,

            username: <>{data?.userName}</>,
            role: <>{data?.role}</>,
          }))
        : [];
    setUserList(arr);
  };

  useEffect(() => {
    if (openAddUser) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openAddUser]);

  useEffect(() => {
    dispatch(getUsers());
  }, [openAddUser, message, openAddUser]);

  useEffect(() => {
    if (Users_List) {
      const data = [...Users_List].reverse();
      makeRow(data);
    }
    if (isSuccess == true) {
      dispatch(resetUser());
    }
  }, [dispatch, isError, message, isSuccess, isList, openAddUser]);

  return (
    <>
      {openAddUser === true && (
        <AddUser
          setModalClick={() => {
            setOpenAddUser(false);
            setSelectedUser(null);
          }}
          selectedUser={selectedUser}
        />
      )}
      <div className="panel-card">
        <div className="panel-body">
          <Search
            handleSearch={handleSearch}
            handleDepartSearch={handleDepartSearch}
            searchFilter={true}
            buttonText="Add New User"
            setModalClick={() => setOpenAddUser(true)}
          />
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={userList
                ?.filter((row) => {
                  if (searchQuery === "") return userList;
                  const name =
                    row?.name?.props?.children[1]?.props?.children?.toLowerCase();
                  const department =
                    row?.department?.props?.children?.toLowerCase();
                  const email = row?.email?.props?.children?.toLowerCase();
                  const phone = row?.phone?.props?.children?.toLowerCase();
                  const username = row?.username?.props.children?.toLowerCase();
                  const role = row?.role?.props?.children?.toLowerCase();
                  return (
                    name?.includes(searchQuery?.toLowerCase()) ||
                    department?.includes(searchQuery?.toLowerCase()) ||
                    email?.includes(searchQuery?.toLowerCase()) ||
                    phone?.includes(searchQuery?.toLowerCase()) ||
                    username?.includes(searchQuery?.toLowerCase()) ||
                    role?.includes(searchQuery?.toLowerCase())
                  );
                })
                .filter((o) => {
                  if (departSearch == "AllDepartment") {
                    return o;
                  } else {
                    const department =
                      o?.department?.props?.children?.toLowerCase();
                    return department?.includes(departSearch.toLowerCase());
                  }
                })}
              pagination={true}
              responsive={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
