import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import profileDummyImage from "../../../assets/images/dummy-profile.jpg";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveImage } from "../../../features/MyProfile/profileSlice";
import {
  addUsers,
  resetUser,
  deleteUser,
  updateUser,
  getUsers,
} from "../../../features/users/userSlice";
import Trash from "../../../assets/images/bin.png";
import Edit from "../../../assets/images/edit.png";

export default function AddUser({ setModalClick, selectedUser = null }) {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [errorNameMessage, setNameErrorMessage] = useState("");
  const [errorUsernameMessage, setErrorUsernameMessage] = useState("");
  const [errorDepartmentMessage, setErrorDepartmentMessage] = useState("");
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [errorPhoneMessage, setErrorPhoneMessage] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorRoleMessage, setErrorRoleMessage] = useState("");
  const [editState, setEditState] = useState(false);
  const [userFormData, setUserFormData] = useState({
    firstName: selectedUser ? selectedUser.name : "",
    userName: selectedUser ? selectedUser.userName : "",
    department: selectedUser ? selectedUser.department : "",
    email: selectedUser ? selectedUser.email : "",
    contactNo: selectedUser ? selectedUser.contactNo : "",
    role: selectedUser ? selectedUser.role : "",
    profile: selectedUser ? selectedUser.profile : "",
  });
  const { Users_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.users);
  const uploadFileHandler = async (e, func = setSelectedImage) => {
    func(e.target.files[0]);
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);
    const { payload } = await dispatch(saveImage(bodyFormData));
    setUserFormData({ ...userFormData, profile: payload?.data });
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();
    if (errorNameMessage === "Name is required!") {
      setNameErrorMessage("");
    } else if (errorUsernameMessage === "Username is required!") {
      setErrorUsernameMessage("");
    } else if (errorDepartmentMessage === "Department is required!") {
      setErrorDepartmentMessage("");
    } else if (errorEmailMessage === "Email Address is required!") {
      setErrorEmailMessage("");
    } else if (errorPhoneMessage === "Phone Number is required!") {
      setErrorPhoneMessage("");
    } else if (errorRoleMessage === "Role is required!") {
      setErrorRoleMessage("");
    } else if (errorPassword === "Password is required!") {
      setErrorPassword("");
    }
    
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...userFormData };
    newFormData[fieldName] = fieldValue;
    setUserFormData(newFormData);
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[a-zA-Z\s]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };
  const AddForm = (event) => {
    event.preventDefault();
    if (
      userFormData.firstName === "" &&
      userFormData.userName === "" &&
      userFormData.department === "" &&
      userFormData.email === "" &&
      userFormData.contactNo === "" &&
      userFormData.role === ""
    ) {
      toast.error("Fill form data to proceed!");
    } else if (userFormData.firstName === "") {
      setNameErrorMessage("Name is required!");
      // toast.error("Name is required!");
    } else if (userFormData.userName === "") {
      setErrorUsernameMessage("Username is required!");
      // toast.error("Username is required!");
    } else if (userFormData.department === "") {
      setErrorDepartmentMessage("Department is required!");
      // toast.error("Department is required!");
    } else if (userFormData.email === "") {
      setErrorEmailMessage("Email Address is required!");
      // toast.error("Email Address is required!");
    } else if (userFormData.contactNo === "") {
      setErrorPhoneMessage("Phone Number is required!");
      // toast.error("Phone Number is required!");
    } else if (userFormData.role === "") {
      setErrorRoleMessage("Role is required!");
      // toast.error("Role is required!");
    } else if (userFormData.password === "") {
      setErrorPassword("Password is required!");
      // toast.error("Role is required!");
    } else {
      if (userFormData.profile === "") {
        const res = dispatch(
          addUsers({
            ...userFormData,
            profile:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png",
          })
        );
      } else {
        const res = dispatch(addUsers(userFormData));
      }
    }
  };

  const updateForm = async (event) => {
    event.preventDefault();
    if (
      userFormData.firstName === "" &&
      userFormData.userName === "" &&
      userFormData.department === "" &&
      userFormData.email === "" &&
      userFormData.contactNo === "" &&
      userFormData.role === ""
    ) {
      toast.error("Fill form data to proceed!");
    } else if (userFormData.firstName === "") {
      setNameErrorMessage("Name is required!");
    } else if (userFormData.userName === "") {
      setErrorUsernameMessage("Username is required!");
    } else if (userFormData.department === "") {
      setErrorDepartmentMessage("Department is required!");
    } else if (userFormData.email === "") {
      setErrorEmailMessage("Email Address is required!");
    } else if (userFormData.contactNo === "") {
      setErrorPhoneMessage("Phone Number is required!");
    } else if (userFormData.role === "") {
      setErrorRoleMessage("Role is required!");
    } else if (userFormData.password === "") {
      setErrorPassword("Password is required!");
    } else {
      try {
        await dispatch(
          updateUser({
            id: selectedUser.uId,
            ...userFormData,
          })
        );
        await dispatch(getUsers());
        setEditState(false);
        toast.success("User updated!");
      } catch (error) {
        toast.error("Unable to update user!");
      }
    }
  };
  useEffect(() => {
    if (isSuccess == true && message !== "") {
      toast.success(message);
      dispatch(resetUser());
      setTimeout(() => {
        setModalClick(false);
      }, 1500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(resetUser());
    }
  }, [message, isSuccess]);
  const { departments } = useSelector((state) => state.common);
  return (
    <>
      <div className="modal-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>{selectedUser ? "User Details" : "Add Users"}</h2>
              <div className="d-flex align-items-center">
                {selectedUser && (
                  <img
                    onClick={async () => {
                      try {
                        const result = await dispatch(
                          deleteUser(selectedUser._id)
                        );
                        if (result.payload.status == "Success") {
                          await dispatch(resetUser());
                        }
                        toast.success("User deleted successfully!");
                        setModalClick();
                      } catch (error) {
                        toast.error("Unable to delete user please try again!");
                      }
                    }}
                    src={Trash}
                    className="bin"
                    alt="trash"
                  />
                )}
                {selectedUser && (
                  <img
                    src={Edit}
                    className="bin"
                    alt="Edit"
                    onClick={() => setEditState(!editState)}
                  />
                )}
                <img onClick={setModalClick} src={cross} alt="cross" />
              </div>
            </div>
            <div className="modal-body">
              <div className="add-user-wrapper">
                <div className="add-form">
                  <ul className="list-style-none">
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Name <small>*</small>
                        </label>
                        <input
                          maxLength={50}
                          type="text"
                          placeholder="Add Name"
                          className="form-control"
                          name="firstName"
                          onChange={handleAddFormChange}
                          defaultValue={selectedUser && selectedUser.firstName}
                          disabled={selectedUser && !editState}
                        />
                      </div>
                      <p className="errorMessage">
                        {errorNameMessage ? errorNameMessage : ""}
                      </p>
                    </li>
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Username <small>*</small>
                        </label>
                        <input
                          type="text"
                          maxLength={50}
                          placeholder="Add Username"
                          className="form-control"
                          name="userName"
                          onChange={handleAddFormChange}
                          defaultValue={selectedUser && selectedUser.userName}
                          disabled={selectedUser && !editState}
                        />
                      </div>
                      <p className="errorMessage">
                        {errorUsernameMessage ? errorUsernameMessage : ""}
                      </p>
                    </li>
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Department <small>*</small>
                        </label>
                        <select
                          name="department"
                          onChange={handleAddFormChange}
                          id=""
                          className="form-control"
                          defaultValue={selectedUser && selectedUser.department}
                          disabled={selectedUser && !editState}
                        >
                          <option value="">Select</option>
                          {departments.data?.map((department) => (
                            <option value={department.name}>
                              {department.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p>
                        {errorDepartmentMessage ? errorDepartmentMessage : ""}
                      </p>
                    </li>
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Email Address <small>*</small>
                        </label>
                        <input
                          type="email"
                          placeholder="Add Email Address"
                          className="form-control"
                          name="email"
                          onChange={handleAddFormChange}
                          defaultValue={selectedUser && selectedUser.email}
                          disabled={selectedUser && !editState}
                        />
                      </div>
                      <p className="errorMessage">
                        {errorEmailMessage ? errorEmailMessage : ""}
                      </p>
                    </li>
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Phone Number <small>*</small>
                        </label>
                        <input
                          type="number"
                          placeholder="Add Phone Number"
                          className="form-control"
                          name="contactNo"
                          onChange={handleAddFormChange}
                          defaultValue={selectedUser && selectedUser.contactNo}
                          disabled={selectedUser && !editState}
                        />
                      </div>
                      <p className="errorMessage">
                        {errorPhoneMessage ? errorPhoneMessage : ""}
                      </p>
                    </li>
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Password <small>*</small>
                        </label>
                        <input
                          type="password"
                          placeholder="Password"
                          className="form-control"
                          name="password"
                          onChange={handleAddFormChange}
                          disabled={selectedUser && !editState}
                        />
                      </div>
                      <p className="errorMessage">
                        {errorPassword ? errorPassword : ""}
                      </p>
                    </li>
                    <li>
                      <div className="form-group">
                        <label htmlFor="">
                          Role <small>*</small>
                        </label>
                        <select
                          name="role"
                          onChange={handleAddFormChange}
                          id=""
                          className="form-control"
                          defaultValue={selectedUser && selectedUser.role}
                          disabled={selectedUser && !editState}
                        >
                          <option value="">Select</option>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                      <p className="errorMessage">
                        {errorRoleMessage ? errorRoleMessage : ""}
                      </p>
                    </li>
                  </ul>
                </div>
                {selectedUser ? (
                  <div className="file-uploader">
                    <figure>
                      <img
                        alt="Image not found"
                        width={"250px"}
                        src={
                          editImage
                            ? URL.createObjectURL(editImage)
                            : selectedUser.profile
                        }
                      />
                    </figure>
                    {/* {editState && (
                      <button
                        className="simple-btn"
                        onChange={(event) => {
                          uploadFileHandler(event, setEditImage);
                        }}
                      >
                        <input type="file" accept="image/gif, image/jpeg" />
                        Upload Photo
                      </button>
                    )} */}
                  </div>
                ) : (
                  <div className="file-uploader">
                    <figure>
                      {selectedImage && (
                        <img
                          alt="Image not found"
                          width={"250px"}
                          src={URL.createObjectURL(selectedImage)}
                        />
                      )}
                      {!selectedImage && (
                        <img src={profileDummyImage} alt="profileDummyImage" />
                      )}
                    </figure>
                    {!selectedImage && (
                      <button
                        className="simple-btn"
                        onChange={(event) => {
                          uploadFileHandler(event);
                        }}
                      >
                        <input type="file" accept="image/gif, image/jpeg" />
                        Upload Photo
                      </button>
                    )}
                    {selectedImage && (
                      <button
                        className="simple-btn"
                        onClick={() => setSelectedImage(null)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="flex content-justify-end mt-4">
                {selectedUser ? (
                  editState && (
                    <div className="flex content-justify-end">
                      <button
                        className="export-btn me-2"
                        type="button"
                        onClick={() => setEditState(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="simple-btn"
                        type="button"
                        onClick={updateForm}
                      >
                        Update
                      </button>
                    </div>
                  )
                ) : (
                  <button
                    className="simple-btn"
                    type="button"
                    onClick={AddForm}
                  >
                    Create New Users
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
