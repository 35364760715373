import React, { useEffect, useState } from "react";
import "./shiftManagements.scss";
import Clock from "../../assets/images/clock.svg";
import Verified from "../../assets/images/verified.svg";
import AddShift from "../../components/modals/add-shift/AddShift";
import Export from "../../components/modals/export/Export";
import { useDispatch, useSelector } from "react-redux";
import {
  getExceedingUser,
  getShifts,
  resetShift,
} from "../../features/shifts/shiftSlice";

import "../../components/shiftSchedulingBar/shiftSchedulingBar.scss";
import plusBtn from "../../assets/images/plus.svg";
import searchIcon from "../../assets/images/header-search.svg";
import exportIcon from "../../assets/images/export.svg";
import issue from "../../assets/images/issue.svg";
import upload from "../../assets/images/upload.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import AddNewShift from "../../components/shiftSchedulingBar/AddNewShift";
import AssignShift from "../../components/modals/assign-shift/AssignShift";
import Multiselect from "multiselect-react-dropdown";
import Issues from "../../components/shiftSchedulingBar/Issues";
import { getSites } from "../../features/sites/siteSlice";
import { DatePicker, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format, getHours } from "date-fns";
import Data from "./Data";
import RequestReplacementModal from "../../components/modals/shift-replacement-modal/RequestReplacementModal";
import ReplacementDoneModal from "../../components/modals/shift-replacement-modal/ReplacementDoneModal";
import Dropdown from "react-bootstrap/Dropdown";

//for daily mapping
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
const { allowedDays } = DateRangePicker;
export default function ShiftManagement() {
  const vacantShifts = useSelector((state) => state.shifts.vacantShifts);
  const issues = useSelector((state) => state.shifts.issueShift);
  const totalIssues = issues?.length - 1 + vacantShifts?.length - 1;

  const [openAddUser, setOpenAddUser] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showReplacementModal, setShowReplacementModal] = useState(false);
  const [showIssue, setShowIssue] = useState(false);
  const [siteFilter, setSiteFilter] = useState("allSites");
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [timeFilter, setTimeFilter] = useState("week");
  const [jobFilter, setJobFilter] = useState("allJobs");
  const [userFilter, setUserFilter] = useState("allUsers");
  const [positionFilter, setpositionFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFilter, setDateFilter] = useState(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const dateAfter7Days = new Date();
    dateAfter7Days.setDate(new Date().getDate() + 7);
    return [new Date(currentDate), new Date(dateAfter7Days)];
  });
  const [dailyDate, setDailyDate] = useState(
    moment(new Date()).startOf("day").valueOf()
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).startOf("day").add(1, "day").valueOf()
  );
  const [timeline, setTimeline] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const user_list = useSelector((state) => state?.users?.Users_List);
  const generateTimeline = () => {
    const [startDate, endDate] = dateFilter;
    const getDaysArray = () => {
      for (
        var arr = [], d = new Date(startDate);
        d < new Date(endDate);
        d.setDate(d.getDate() + 1)
      ) {
        arr.push(format(new Date(d), "dd/MM/yyyy"));
      }

      return arr;
    };

    return getDaysArray();
  };
  const generateDailyTimeline = () => {
    return [
      "12 AM",
      "1 AM",
      "2 AM",
      "3 AM",
      "4 AM",
      "5 AM",
      "6 AM",
      "7 AM",
      "8 AM",
      "9 AM",
      "10 AM",
      "11 AM",
      "12 PM",
      "1 PM",
      "2 PM",
      "3 PM",
      "4 PM",
      "5 PM",
      "6 PM",
      "7 PM",
      "8 PM",
      "9 PM",
      "10 PM",
      "11 PM",
    ];
  };
  useEffect(() => {
    if (timeFilter == "week") {
      setTimeline(generateTimeline());
    } else if (timeFilter == "day") {
      setTimeline(generateDailyTimeline());
    }
  }, [dateFilter]);

  const [selectedShift, setSelectedShift] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Shift_List, isError, message, isSuccess, isList } = useSelector(
    (state) => state.shifts
  );
  const { Sites_List } = useSelector((state) => state.sites);
  const [userList, setUserList] = useState([]);
  const dispatch = useDispatch();
  const [ph, setPh] = useState("ViewPositions");
  const calculateDuration = (startTime, endTime) => {
    const start = new Date("2000-01-01 " + startTime);
    const end = new Date("2000-01-01 " + endTime);
    const duration = end - start;

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return { hours, minutes };
  };

  const formatDuration = (startTime, endTime) => {
    const { hours, minutes } = calculateDuration(startTime, endTime);
    if ((hours || minutes) < 0) {
      return `0:0H`;
    } else {
      return `${hours}:${minutes}H`;
    }
  };
  const columns = [
    {
      name: (
        <div className="table-content">
          <div className="search-filter">
            <select onChange={(e) => setpositionFilter(e.target.value)}>
              <option value="all">View by Job Positions</option>
              {Shift_List?.map((shift) => (
                <option value={shift._id.position}>{shift._id.position}</option>
              ))}
            </select>
          </div>
        </div>
      ),
      selector: (row) => {
        return row.position;
      },
      wrap: true,
    },
    ...timeline?.map((time, idx) => ({
      name: <div className="table-content">{time}</div>,
      selector: (row) => row[idx]?.[time],
      wrap: true,
    })),
  ];
  const handleShiftClick = (shift) => {
    setSelectedShift(shift);
    setIsModalOpen(true);
  };
  const handleOpenModal = (shift) => {
    setSelectedShift({ shift });
    setIsModalOpen(true);
  };
  const makeRow = (shiftData) => {
    var arr =
      Array.isArray(shiftData) && shiftData?.length > 0
        ? shiftData
            ?.filter((shift) => {
              if (positionFilter == "all") {
                return true;
              } else {
                return positionFilter == shift?._id.position;
              }
            })
            ?.map((data, id) => {
              return {
                position: (
                  <div className="jobPosition">
                    <h3>{data?._id.position}</h3>
                  </div>
                ),
                ...timeline?.map((tl) => {
                  return {
                    [tl]: data?.data
                      ?.filter((d) => {
                        if (searchQuery.length) {
                          const lowerCaseQuery = searchQuery.toLowerCase();
                          return (
                            d?.assignedUser[0]?.firstName
                              ?.toLowerCase()
                              .includes(lowerCaseQuery) ||
                            d?.assignedUser[0]?.lastName
                              ?.toLowerCase()
                              .includes(lowerCaseQuery) ||
                            d?.siteName
                              ?.toLowerCase()
                              .includes(lowerCaseQuery) ||
                            d?.position?.toLowerCase().includes(lowerCaseQuery)
                          );
                        }
                        return true;
                      })
                      ?.map((e) => {
                        const date = new Date(e.date);
                        if (format(date, "dd/MM/yyyy") == tl) {
                          return e?.assignedUser.length ? (
                            e.clash ? (
                              <>
                                <div
                                  className="warning_card"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleOpenModal({
                                      ...e,
                                      isReasign: e.clash,
                                    })
                                  }
                                >
                                  <div className="role">
                                    <div className="d-flex align-items-center">
                                      <img src={Verified} alt="verfied" />
                                      <h5>
                                        {`${new Date(
                                          e.startTime
                                        ).toLocaleString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}{" "}
                                        -
                                        {`${new Date(e.endTime).toLocaleString(
                                          "en-US",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          }
                                        )}`}
                                      </h5>
                                    </div>
                                    <h4>{e.siteName}</h4>
                                    <p>{e.position}</p>
                                  </div>
                                  <span>
                                    {formatDuration(
                                      new Date(e.startTime).toLocaleString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      ),
                                      new Date(e.endTime).toLocaleString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )
                                    )}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="assign-card"
                                  style={
                                    e.published
                                      ? {
                                          background: e.color,
                                          margin: "10px 0",
                                          cursor: "pointer",
                                          color: "white",
                                        }
                                      : {
                                          border: `1px solid ${e.color}`,
                                          color: e.color,
                                          cursor: "pointer",
                                          background: "white",
                                        }
                                  }
                                >
                                  <time dateTime="">
                                    {`${new Date(e.startTime).toLocaleString(
                                      "en-US",
                                      {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      }
                                    )}`}
                                    -
                                    {`${new Date(e.endTime).toLocaleString(
                                      "en-US",
                                      {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      }
                                    )}`}
                                    <span
                                      style={
                                        e.published
                                          ? {
                                              background: "white",
                                              color: e.color,
                                            }
                                          : {
                                              background: e.color,
                                              color: "white",
                                            }
                                      }
                                    >
                                      {formatDuration(
                                        new Date(e.startTime).toLocaleString(
                                          "en-US",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          }
                                        ),
                                        new Date(e.endTime).toLocaleString(
                                          "en-US",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          }
                                        )
                                      )}
                                    </span>
                                  </time>
                                  <h6
                                    onClick={() => handleOpenModal(e)}
                                    style={
                                      !e.published
                                        ? {
                                            color: e.color,
                                          }
                                        : {
                                            color: "white",
                                          }
                                    }
                                  >
                                    {e.siteName}
                                  </h6>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img
                                      src={e.assignedUser[0].profile || Clock}
                                      alt="userImage"
                                    />
                                    {user?.role != "admin" && (
                                      <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                          ...
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={
                                              user?.role != "admin"
                                                ? () => {
                                                    setSelectedShiftId(e._id);
                                                    setShowReplacementModal(
                                                      true
                                                    );
                                                  }
                                                : null
                                            }
                                          >
                                            Replacement Request
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                          ) : (
                            <div
                              className="vacant_shift"
                              onClick={() => handleOpenModal(e)}
                              style={{
                                background: e.color,
                                cursor: "pointer",
                                color: "white",
                              }}
                            >
                              <p>+ vacant-shift</p>
                            </div>
                          );
                        }
                        return null;
                      }),
                  };
                }),
              };
            })
        : [];
    setUserList(arr);
  };
  const handleClose = () => setShow(false);
  const handleCloseIssue = () => setShowIssue(false);
  const handleShow = () => setShow(true);
  const handleShowIssue = () => setShowIssue(true);

  useEffect(() => {
    if (!show) {
      dispatch(
        getShifts({
          startDate: dateFilter[0],
          endDate: dateFilter[1],
          site: siteFilter,
          position: jobFilter,
          user: userFilter,
        })
      );
    }
    if (!isModalOpen) {
      dispatch(getSites());
      dispatch(
        getShifts({
          startDate: dateFilter[0],
          endDate: dateFilter[1],
          site: siteFilter,
          position: jobFilter,
          user: userFilter,
        })
      );
    }
  }, [
    show,
    isModalOpen,
    openAddUser,
    siteFilter,
    jobFilter,
    userFilter,
    dailyDate,
  ]);

  useEffect(() => {
    // const shiftBodyData = {filter: filter, startDate: weekDuration?.startDate, endDate: weekDuration?.endDate}
    if (openAddUser) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openAddUser]);
  useEffect(() => {
    dispatch(
      getShifts({
        startDate: dateFilter[0],
        endDate: dateFilter[1],
        site: siteFilter,
        position: jobFilter,
        user: userFilter,
      })
    );
    setTimeline(generateTimeline());
  }, [dateFilter, openAddUser, siteFilter, jobFilter, userFilter]);

  useEffect(() => {
    if (timeFilter == "week") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const dateAfter7Days = new Date();
      dateAfter7Days.setDate(new Date().getDate() + 7);
      dispatch(
        getShifts({
          startDate: new Date(currentDate),
          endDate: new Date(dateAfter7Days),
          site: siteFilter,
          position: jobFilter,
          user: userFilter,
        })
      );
    } else if (timeFilter == "month") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const dateAfter30Days = new Date();
      dateAfter30Days.setDate(new Date().getDate() + 30);
      dispatch(
        getShifts({
          startDate: new Date(currentDate),
          endDate: new Date(dateAfter30Days),
          site: siteFilter,
          position: jobFilter,
          user: userFilter,
        })
      );
    }
  }, [timeFilter]);
  useEffect(() => {
    if (openExport) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openExport]);

  useEffect(() => {
    dispatch(getSites());
    dispatch(getExceedingUser());
  }, []);
  useEffect(() => {
    // const shiftBodyData = {filter: filter, startDate: weekDuration?.startDate, endDate: weekDuration?.endDate}
    if (Shift_List) {
      // const data = [...Shift_List].reverse();
      makeRow(Shift_List);
    }
    if (isSuccess == true) {
      dispatch(resetShift());
    }
  }, [
    dispatch,
    isError,
    message,
    isSuccess,
    isList,
    positionFilter,
    searchQuery,
    Shift_List,
  ]);
  const [groups, setGroups] = useState(
    (() => {
      let groups = [];
      Shift_List?.map((shift) => {
        groups.push({
          id: shift._id.position,
          title: shift._id.position,
        });
      });
      return groups;
    })()
  );
  const [items, setItems] = useState(
    (() => {
      let items = [];
      Shift_List?.map((shift) => {
        shift.data?.map((data) => {
          items.push({
            ...data,
            id: data._id,
            group: shift._id.position,
            title: "Outlet" + Math.random(),
            start_time: moment(new Date(data.startTime)),
            end_time: moment(new Date(data.endTime)),
            assignedUser: data.assignedUser,
          });
        });
      });
      return items;
    })()
  );
  useEffect(() => {
    if (timeFilter == "day") {
      setGroups(
        (() => {
          let groups = [];
          Shift_List?.map((shift) => {
            groups.push({
              id: shift._id.position,
              title: shift._id.position,
            });
          });
          return groups;
        })()
      );
      setItems(
        (() => {
          let items = [];
          Shift_List?.map((shift) => {
            shift.data
              ?.filter((d) => {
                if (searchQuery.length) {
                  const lowerCaseQuery = searchQuery.toLowerCase();
                  return (
                    d?.assignedUser[0]?.firstName
                      ?.toLowerCase()
                      .includes(lowerCaseQuery) ||
                    d?.assignedUser[0]?.lastName
                      ?.toLowerCase()
                      .includes(lowerCaseQuery) ||
                    d?.siteName?.toLowerCase().includes(lowerCaseQuery) ||
                    d?.position?.toLowerCase().includes(lowerCaseQuery)
                  );
                }
                return true;
              })
              ?.map((data) => {
                items.push({
                  ...data,
                  id: data._id,
                  group: shift._id.position,
                  title: "Outlet" + Math.random(),
                  start_time: moment(new Date(data.startTime)),
                  end_time: moment(new Date(data.endTime)),
                  assignedUser: data.assignedUser,
                });
              });
          });
          return items;
        })()
      );
    }
  }, [Shift_List, timeFilter, searchQuery]);
  return (
    <>
      <>
        {openAddUser === true && (
          <AddShift setModalClick={() => setOpenAddUser(false)} />
        )}
        {openExport === true && (
          <Export
            setModalClicks={() => setOpenExport(false)}
            openExport={openExport}
          />
        )}
        <div className="panel-card">
          <div className="panel-body scheduling_table">
            <div className="search-wrappers">
              <div className="search-left-side"></div>
              <div className="search-right-side flex">
                <button
                  className="export-btn"
                  onClick={() => setOpenExport(true)}
                >
                  <img src={exportIcon} alt="exportIcon" />
                  Export
                </button>
                <button className="export-btn issue" onClick={handleShowIssue}>
                  <img src={issue} alt="issue" />
                  Issues ({totalIssues})
                </button>
                <button
                  className="export-btn"
                  onClick={() => setOpenAddUser(true)}
                >
                  <img src={upload} alt="upload" />
                  Publish
                </button>
                <button className="theme-btn" onClick={handleShow}>
                  <img src={plusBtn} alt="plusBtn" />
                  Add Shift
                </button>
              </div>
            </div>
            <div className="search-wrappers">
              <div className="search-right-side flex align-items-md-stretch">
                <Multiselect
                  displayValue="key"
                  singleSelect
                  onRemove={() => setSiteFilter("allSites")}
                  onSelect={(e) => {
                    setSiteFilter(e[0].value);
                  }}
                  placeholder="All Sites"
                  options={Sites_List?.map((site) => ({
                    key: site?.name,
                    value: site?._id,
                  }))}
                  showArrow={false}
                />
                <Multiselect
                  singleSelect
                  displayValue="key"
                  onRemove={function noRefCheck() {
                    setJobFilter("allJobs");
                  }}
                  onSelect={function noRefCheck(e) {
                    setJobFilter(e[0].key);
                  }}
                  placeholder="All Job Positions"
                  options={Shift_List?.map(({ _id }) => ({
                    value: _id.position,
                    key: _id.position,
                  }))}
                  showCheckbox
                />
                <Multiselect
                  displayValue="key"
                  singleSelect
                  onRemove={function noRefCheck() {
                    setUserFilter("allUsers");
                  }}
                  onSelect={function noRefCheck(e) {
                    setUserFilter(e[0].value);
                  }}
                  placeholder="All Employees"
                  options={user_list
                    ?.filter((user) => user.role == "user")
                    ?.map((user) => ({
                      value: user._id,
                      key: `${user.firstName} ${user.lastName}`,
                    }))}
                  showCheckbox
                />
                <form action="" className="header-search h-100">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button>
                    <img src={searchIcon} alt="searchIcon" />
                  </button>
                </form>
                <div className="search-filter h-100">
                  <select
                    onChange={(e) => {
                      setTimeFilter(e.target.value);
                    }}
                  >
                    <option value="week">Select Filter</option>
                    <option value="week">Week</option>
                    <option value="day">Day</option>
                    <option value="month">Month</option>
                  </select>
                </div>
                <div className="week-selector">
                  {timeFilter == "day" ? (
                    <DatePicker
                      placeholder="yyyy-mm-dd"
                      className="h-100"
                      onOk={(e) => {
                        setDailyDate(
                          moment(new Date(e)).startOf("day").valueOf()
                        );
                        setendDate(
                          moment(new Date(e))
                            .startOf("day")
                            .add(1, "day")
                            .valueOf()
                        );
                        setDateFilter([e, undefined]);
                      }}
                    />
                  ) : (
                    <DateRangePicker
                      placeholder="yyyy-mm-dd ~ yyyy-mm-dd"
                      defaultValue={() => {
                        //if no date is passed it will use this as default date
                        //current date ---> curent date + 7 days
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0);
                        const dateAfter7Days = new Date();
                        dateAfter7Days.setDate(
                          new Date().getDate() + timeFilter == "week" ? 7 : 30
                        );

                        return [
                          new Date(currentDate),
                          new Date(dateAfter7Days),
                        ];
                      }}
                      ranges={[]}
                      disabledDate={allowedDays(
                        timeFilter == "week" ? 7 : timeFilter == "day" ? 1 : 30
                      )}
                      onOk={(e) => {
                        const [startDate, endDate] = e;
                        setDateFilter([startDate, endDate]);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="search-left-side"></div>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              backdrop="static"
              placement="end"
            >
              <AddNewShift handleClose={handleClose} />
            </Offcanvas>
            <Offcanvas
              show={showIssue}
              onHide={handleCloseIssue}
              backdrop="static"
              placement="end"
            >
              <Issues />
            </Offcanvas>
            <div className="table-responsive">
              {timeFilter == "day" ? (
                groups.length != 0 && items.length != 0 ? (
                  <Timeline
                    canResize={false}
                    groups={groups}
                    items={items}
                    stackItems={true}
                    visibleTimeStart={dailyDate}
                    visibleTimeEnd={endDate}
                    lineHeight={120}
                    itemHeightRatio={0.8}
                    canMove={false}
                    canChangeGroup={false}
                    itemRenderer={({
                      item: e,
                      itemContext,
                      getItemProps,
                      getResizeProps,
                    }) => {
                      return e?.assignedUser.length ? (
                        e.clash ? (
                          <div
                            {...getItemProps({
                              style: {
                                cursor: "pointer",
                                // marginBottom: "5px",
                              },
                              className: "warning_card",
                              // className: "border border-danger border-dotted",
                            })}
                            onClick={() =>
                              handleOpenModal({ ...e, isReasign: e.clash })
                            }
                          >
                            <div className="role">
                              <div className="d-flex align-items-center">
                                <img src={Verified} alt="verfied" />
                                <h5>
                                  {`${new Date(e.startTime).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }
                                  )}`}{" "}
                                  -
                                  {`${new Date(e.endTime).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }
                                  )}`}
                                </h5>
                              </div>
                              <h4>{e.siteName}</h4>
                              <p>{e.position}</p>
                            </div>
                            <span>
                              {formatDuration(
                                new Date(e.startTime).toLocaleString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }),
                                new Date(e.endTime).toLocaleString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })
                              )}
                            </span>
                          </div>
                        ) : (
                          <div
                            {...getItemProps({
                              className: "assign-card",
                              style: e.published
                                ? {
                                    background: e.color,
                                    margin: "10px 0",
                                    cursor: "pointer",
                                    color: "white",
                                  }
                                : {
                                    border: `1px solid ${e.color}`,
                                    color: e.color,
                                    cursor: "pointer",
                                    background: "white",
                                  },
                            })}
                          >
                            <time dateTime="" className="lh-base">
                              {`${new Date(e.startTime).toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}`}
                              -
                              {`${new Date(e.endTime).toLocaleString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}`}
                              <span
                                style={
                                  e.published
                                    ? {
                                        background: "white",
                                        color: e.color,
                                      }
                                    : {
                                        background: e.color,
                                        color: "white",
                                      }
                                }
                              >
                                {formatDuration(
                                  new Date(e.startTime).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }
                                  ),
                                  new Date(e.endTime).toLocaleString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })
                                )}
                              </span>
                            </time>
                            <h6
                              onClick={() => handleOpenModal(e)}
                              style={{
                                cursor: "pointer",
                                color: !e.published ? e.color : "white",
                              }}
                            >
                              {e.siteName}
                            </h6>
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={e.assignedUser[0].profile || Clock}
                                alt="userImage"
                              />
                              {user?.role != "admin" && (
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    ...
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={
                                        user?.role != "admin"
                                          ? () => {
                                              setSelectedShiftId(e._id);
                                              setShowReplacementModal(true);
                                            }
                                          : null
                                      }
                                    >
                                      Replacement Request
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        )
                      ) : (
                        <div
                          onClick={() => handleOpenModal(e)}
                          {...getItemProps({
                            style: {
                              background: e.color,
                              cursor: "pointer",
                              color: "white",
                              // marginBottom: "5px",
                            },
                            className: "vacant_shift",
                          })}
                        >
                          <p>+ vacant-shift</p>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <h1>No Shifts to show!</h1>
                )
              ) : (
                <Data
                  columns={columns}
                  userList={userList}
                  responsive={true}
                  onRowClicked={(row) => handleShiftClick(row)}
                />
              )}
            </div>
            {isModalOpen && (
              <Offcanvas
                show={isModalOpen}
                onHide={handleClose}
                backdrop="static"
                placement="end"
              >
                <AssignShift
                  shift={selectedShift?.shift}
                  closeModal={() => setIsModalOpen(false)}
                />
              </Offcanvas>
            )}
            {showReplacementModal && (
              <RequestReplacementModal
                setModalClick={() => {
                  setShowReplacementModal(false);
                  setSelectedShift(null);
                }}
                showDoneModal={() => setShowDoneModal(true)}
                shiftId={selectedShiftId}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            )}
            {showDoneModal && (
              <ReplacementDoneModal
                setModalClick={() => {
                  setShowDoneModal(false);
                }}
                user={selectedUser}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
}
