import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/timeoff`;

const createTimeOff = async (body) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  try {
    const response = await axios.post(API_URL, body, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const getTimeOff = async () => {
  try {
    const config = {
      headers: {
        authorization: localStorage.getItem("JWT_Token"),
        orgid: localStorage.getItem("organization"),
      },
    };
    const response = await axios.get(API_URL, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const getTimeoffRequests = async () => {
  try {
    const config = {
      headers: {
        authorization: localStorage.getItem("JWT_Token"),
        orgid: localStorage.getItem("organization"),
      },
    };
    const response = await axios.get(`${API_URL}/requests`, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const approveTimeOff = async (body) => {
  try {
    const config = {
      headers: {
        authorization: localStorage.getItem("JWT_Token"),
        orgid: localStorage.getItem("organization"),
      },
    };
    const response = await axios.put(`${API_URL}/${body.id}`, body, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const timeOffService = {
  createTimeOff,
  getTimeOff,
  getTimeoffRequests,
  approveTimeOff,
};

export default timeOffService;
