import React, { useState, useEffect } from "react";
import "../modal.scss";
import cross from "../../../assets/images/close.svg";
import Multiselect from "multiselect-react-dropdown";
import DataTable from "react-data-table-component";
import ExportData from "./ExportData.json";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DatePicker, DateRangePicker } from "rsuite";
import { format, getHours } from "date-fns";
import { exportShift } from "../../../features/shifts/shiftSlice";
import { CSVLink } from "react-csv";

export default function Export(props) {
  const { allowedDays } = DateRangePicker;
  const dispatch = useDispatch();
  const user_list = useSelector((state) => state?.users?.Users_List);
  const { Sites_List } = useSelector((state) => state.sites);
  const { Shift_List_Export, isError, message, isSuccess, isList } =
    useSelector((state) => state.shifts);
  const [siteFilter, setSiteFilter] = useState("allSites");
  const [timeFilterExport, setTimeFilterExport] = useState("today");
  const [jobFilter, setJobFilter] = useState("allJobs");
  const [userFilter, setUserFilter] = useState("allUsers");
  const [positionFilter, setpositionFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const dateAfter7Days = new Date();
    dateAfter7Days.setDate(new Date().getDate() + 7);
    return [new Date(currentDate), new Date(dateAfter7Days)];
  });
  const [dailyDate, setDailyDate] = useState(
    moment(new Date()).startOf("day").valueOf()
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).startOf("day").add(1, "day").valueOf()
  );

  const dateSetting = () => {
    const today = new Date();
    if (timeFilterExport === "today") {
      setDailyDate(moment(today).startOf("day").valueOf());
      setendDate(moment(today).startOf("day").add(1, "day").valueOf());
      setDateFilter([today, undefined]);
    } else if (timeFilterExport == "pastweek") {
      const dateFrom = moment(new Date()).subtract(7, "d").format();
      setDateFilter([dateFrom, today]);
    } else if (timeFilterExport == "past1month") {
      const dateFrom = moment(new Date()).subtract(1, "month").format();
      setDateFilter([dateFrom, today]);
    } else if (timeFilterExport == "past3month") {
      const dateFrom = moment(new Date()).subtract(3, "month").format();
      setDateFilter([dateFrom, today]);
    } else if (timeFilterExport == "past1year") {
      const dateFrom = moment(new Date()).subtract(1, "years").format();
      setDateFilter([dateFrom, today]);
    }
  };

  const exportData = [];
  useEffect(() => {
    dateSetting();
  }, [timeFilterExport]);

  const filterCsvRecords = () => {
    dispatch(
      exportShift({
        startDate: dateFilter[0],
        endDate: dateFilter[1],
        site: siteFilter,
        position: jobFilter,
        user: userFilter,
      })
    );
  };

  for (var i = 0; i <= Shift_List_Export?.length - 1; i++) {
    const innerData = Shift_List_Export[i].data;
    for (var j = 0; j <= innerData?.length - 1; j++) {
      exportData.push(innerData[j]);
    }
  }

  const csvData = [
    ["Time", "Employee Name", "Sites", "Job Positions", "Job Details"],
    ...exportData.map(
      ({ startTime, assignedUser, siteName, position, updatedAt, endTime }) => [
        moment(startTime).format("LLL"),
        assignedUser[0] ? assignedUser[0].firstName : "",
        siteName,
        position,
        assignedUser[0]
          ? `Assigned shift to ${moment(updatedAt).format("LL")},  ${moment(
              startTime
            ).format("LT")}-${moment(endTime).format("LT")}`
          : "",
      ]
    ),
  ];

  const columns = [
    {
      name: <div className="table-content">Time</div>,
      selector: (row) => (
        <div className="jobPosition">{moment(row.startTime).format("LLL")}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Employee Name</div>,
      selector: (row) => (
        <div className="jobPosition">{`${row?.assignedUser[0]?.firstName} ${row?.assignedUser[0]?.lastName}`}</div>
      ),
    },
    {
      name: <div className="table-content">Sites</div>,
      selector: (row) => <div className="jobPosition">{row.siteName}</div>,
    },
    {
      name: <div className="table-content">Job Positions</div>,
      selector: (row) => <div className="jobPosition">{row.position}</div>,
    },
    {
      name: <div className="table-content">Details</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row.assignedUser[0]
            ? `Assigned shift to ${row.assignedUser[0].firstName} ${moment(
                row?.updatedAt
              ).format("LL")},  ${moment(row?.startTime).format("LT")}-${moment(
                row?.endTime
              ).format("LT")}`
            : ""}
        </div>
      ),
      width: "450px",
    },
  ];
  return (
    <>
      <div className="modal-wrapper export-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Export</h2>
              <img
                onClick={() => {
                  props.setModalClicks();
                }}
                src={cross}
                alt="cross"
              />
            </div>
            <div className="modal-body">
              <div className="export_modal">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">
                        Filter by <small>*</small>
                      </label>
                      <div className="search-filter">
                        <select
                          onChange={(e) => {
                            setTimeFilterExport(e.target.value);
                          }}
                        >
                          <option value="today">Today</option>
                          <option value="pastweek">Past Week</option>
                          <option value="past1month">Past 1 Month</option>
                          <option value="past3month">Past 3 Month</option>
                          <option value="past1year">Past 1 Year</option>
                          <option value="customdate">Custom Date</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {timeFilterExport === "customdate" && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Shift Start Date <small>*</small>
                        </label>

                        <div className="week-selector">
                          <DateRangePicker
                            placeholder="yyyy-mm-dd ~ yyyy-mm-dd"
                            defaultValue={() => {
                              //if no date is passed it will use this as default date
                              //current date ---> curent date + 7 days
                              const currentDate = new Date();
                              currentDate.setHours(0, 0, 0, 0);
                              const dateAfter7Days = new Date();
                              dateAfter7Days.setDate(
                                new Date().getDate() + timeFilterExport ==
                                  "week"
                                  ? 7
                                  : 30
                              );

                              return [
                                new Date(currentDate),
                                new Date(dateAfter7Days),
                              ];
                            }}
                            ranges={[]}
                            onOk={(e) => {
                              const [startDate, endDate] = e;
                              setDateFilter([startDate, endDate]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mt-3 align-items-end mb-4">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">
                        Sites <small>*</small>
                      </label>

                      <Multiselect
                        displayValue="key"
                        onRemove={() => setSiteFilter("allSites")}
                        onSelect={(e) => {
                          setSiteFilter(e[0].value);
                        }}
                        placeholder="All Sites"
                        options={Sites_List?.map((site) => ({
                          key: site?.name,
                          value: site?._id,
                        }))}
                        showArrow={false}
                        showCheckbox
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">
                        Job Positions <small>*</small>
                      </label>

                      <Multiselect
                        singleSelect
                        displayValue="key"
                        onRemove={function noRefCheck() {
                          setJobFilter("allJobs");
                        }}
                        onSelect={function noRefCheck(e) {
                          setJobFilter(e[0].key);
                        }}
                        placeholder="All Job Positions"
                        options={Shift_List_Export?.map(({ _id }) => ({
                          value: _id.position,
                          key: _id.position,
                        }))}
                        showCheckbox
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">
                        Employees <small>*</small>
                      </label>
                      <Multiselect
                        displayValue="key"
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={function noRefCheck(e) {
                          setUserFilter("allUsers");
                        }}
                        onSearch={function noRefCheck() {}}
                        onSelect={function noRefCheck(e) {
                          setUserFilter(e[0].value);
                        }}
                        placeholder="Employees Name"
                        options={user_list
                          ?.filter((user) => user.role == "user")
                          ?.map((user) => ({
                            value: user._id,
                            key: `${user.firstName} ${user.lastName}`,
                          }))}
                        showCheckbox
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button
                      className="simple-btn"
                      type="button"
                      onClick={() => {
                        filterCsvRecords();
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <div className="table-responsive alternative-colors">
                  <DataTable
                    columns={columns}
                    data={exportData}
                    responsive={true}
                  />
                  <div className="flex content-justify-end mt-4">
                    <CSVLink filename="shifts.csv" data={csvData}>
                      <p className="simple-btn">Export to CSV</p>
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
