import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "./commonService";

export const getDepartments = createAsyncThunk(
  "common/getDepartments",
  async (thunkAPI) => {
    try {
      return await commonService.getDepartments();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getApps = createAsyncThunk(
  "common/getApps",
  async (thunkAPI) => {
    try {
      return await commonService.getApps();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCSC = createAsyncThunk(
  "common/getCSC",
  async (thunkAPI) => {
    try {
      return await commonService.getCSC();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    departments: [],
    apps: [],
    csc: []
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.departments = action.payload.data;
      })
      .addCase(getApps.fulfilled, (state, action) => {
        state.apps = action.payload.data;
      })
      .addCase(getCSC.fulfilled, (state, action) => {
        state.csc = action.payload.data;
      })
  },
});

export default commonSlice.reducer;
