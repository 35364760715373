import { useDispatch, useSelector } from "react-redux";
import cross from "../../../assets/images/close.svg";
import searchIcon from "../../../assets/images/header-search.svg";
import "./RequestReplacement.scss";
import { useEffect, useState } from "react";
import { getReplacementUsers } from "../../../features/users/userSlice";
import { createReplacement } from "../../../features/shifts/shiftSlice";
const RequestReplacementModal = ({
  setModalClick,
  showDoneModal,
  shiftId,
  selectedUser,
  setSelectedUser,
}) => {
  const dispatch = useDispatch();
  const { replacementUsers } = useSelector((state) => state.users);
  useEffect(() => {
    dispatch(getReplacementUsers(shiftId));
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      <div className="modal-wrapper new-site-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-title">
              <h2>Find Replacement</h2>
              <img
                onClick={() => {
                  setModalClick();
                  setSelectedUser(null);
                }}
                src={cross}
                alt="cross"
              />
            </div>
            <div className="modal-body pt-3">
              <p>Select a employees to replace you in this shift</p>
              <form action="" className="header-search  m-0 w-100 mt-3">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                <button>
                  <img src={searchIcon} alt="searchIcon" />
                </button>
              </form>
              {replacementUsers
                ?.slice()
                .filter((user) => {
                  const name = `${user?.firstName} ${user.lastName}`;
                  return name.toLowerCase().includes(searchQuery.toLowerCase());
                })
                .map((user) => (
                  <div
                    className={`replacement ${
                      user?._id == selectedUser?._id ? "active" : ""
                    }`}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedUser(user)}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          user.profile ||
                          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                        }
                        alt="user"
                      />
                      <p className="mb-0">{`${user?.firstName} ${user.lastName}`}</p>
                    </div>
                  </div>
                ))}
              <div className="flex content-justify-end mt-3">
                <button
                  className="simple-btn"
                  type="submit"
                  onClick={async () => {
                    if (selectedUser && Object.keys(selectedUser).length) {
                      const result = await dispatch(
                        createReplacement({
                          shiftId,
                          userTobeReplaced: selectedUser,
                        })
                      );
                      setModalClick();
                      showDoneModal();
                    }
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestReplacementModal;
