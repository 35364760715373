import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/users`;

const usersList = async () => {
  const authToken = localStorage.getItem("JWT_Token");
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      authorization: authToken,
      orgid: organization,
    },
  };
  try {
    const response = await axios.get(API_URL, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const addUsersData = async (userData) => {
  const authToken = localStorage.getItem("JWT_Token");
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      authorization: authToken,
      orgid: organization,
    },
  };
  try {
    const response = await axios.post(API_URL, userData, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const editUsersData = async (userData) => {
  const response = await axios.put(API_URL + "edit-users", userData);
  if (response.data) {
    return response.data;
  }
};

const deleteUsersData = async (ID) => {
  const response = await axios.put(API_URL + `delete-users/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const saveImageU = async (bodyFormData) => {
  const response = await axios.post(API_URL + "add-uploads/s3", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.data) {
    return response.data;
  }
};

const updatePassU = async (passData) => {
  const response = await axios.post(API_URL + "change-password", passData);
  return response.data;
};

const deletedRowsU = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const getReplacementUsers = async (shiftId) => {
  const authToken = localStorage.getItem("JWT_Token");
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      authorization: authToken,
      orgid: organization,
    },
  };
  try {
    const response = await axios.get(
      `${API_URL}/replacement/${shiftId}`,
      config
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const deleteUser = async (userId) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  try {
    const response = await axios.delete(`${API_URL}/${userId}`, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateUser = async (userId, body) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("JWT_Token"),
      orgid: localStorage.getItem("organization"),
    },
  };
  try {
    const response = await axios.put(`${API_URL}/${userId}`, body, config);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const userService = {
  usersList,
  addUsersData,
  editUsersData,
  deleteUsersData,
  saveImageU,
  updatePassU,
  deletedRowsU,
  getReplacementUsers,
  deleteUser,
  updateUser,
};

export default userService;
