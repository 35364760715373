import DOMPurify from "dompurify";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export const convertDraftToHTML = (draft) => {
  if (!draft) {
    return "";
  }
  return createMarkup(draftToHtml(convertToRaw(draft.getCurrentContent())));
};

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};
