import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/site`;
const authToken = localStorage.getItem("JWT_Token");
 

// Get list of Contributors user
const siteList = async () => {
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      authorization: authToken,
      orgid: organization,
    },
  };
  const response = await axios.get(`${API_URL}`,config);
  if (response.data) {
    return response.data;
  }
};

// Add Contributors user
const addSitesData = async (userData) => {
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: authToken,
      orgid: organization,
    },
  };
  const response = await axios.post(API_URL, userData,config);
  if (response.data) {
    return response.data;
  }
};

const editSitesData = async (userData) => {
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authToken,
      orgid: organization,
    },
  };
  const response = await axios.put(API_URL + "edit-users", userData,config);
  if (response.data) {
    return response.data;
  }
};

const deleteSitesData = async (ID) => {
  const organization = localStorage.getItem("organization");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authToken,
      orgid: organization,
    },
  };
  const response = await axios.put(API_URL + `delete-users/${ID}`,config);
  if (response.data) {
    return response.data;
  }
};
const userService = {
  siteList,
  addSitesData,
  editSitesData,
  deleteSitesData
};

export default userService;
