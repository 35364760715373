import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "./../../features/auth/authSlice";

const JumpAuthorize = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const bearerToken = searchParams.get("token");
  const splitOrg = search?.toString().split("organizationId=");
  const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

  useEffect(() => {
    dispatch(verifyToken({
      bearerToken: bearerToken,
      orgId: organizationId
    }));
  }, []);

  useEffect(() => {
    console.log(user)
    if (user) {
      navigate("/dashboard");
    }
  }, [user])

  return (
    <div></div>
  );
};

export default JumpAuthorize;
