import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import { PanelLayout } from "./PanelLayout";
import UserManagement from "../pages/user-management/UserManagement";
import SitesManagement from "../pages/sites-management/SitesManagement";
import ShiftManagement from "../pages/shift-management/ShiftManagement";
import Login from "../pages/login/Login";
import JumpAuthorize from "../pages/login/JumpAuthorize";
import { LoginLayout } from "./LoginLayout";
import Organization from "../pages/login/Organization";
import TimeOff from "../pages/time-off/TimeOff";
import HelpSupport from "../pages/help-support/HelpSupport";
import Setting from "../pages/setting/Setting";
import { useSelector } from "react-redux";

export const MainRouting = () => {
  const user = useSelector((state) => state?.auth?.user);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/dashboard"
          errorElement={<ErrorPage />}
          element={<PanelLayout />}
        >
          {user?.role == "admin" && (
            <Route index element={<UserManagement />}></Route>
          )}
          {user?.role == "admin" && (
            <Route
              path="/dashboard/users-management"
              element={<UserManagement />}
            ></Route>
          )}
          {user?.role == "admin" && (
            <Route
              path="/dashboard/sites-management"
              element={<SitesManagement />}
            ></Route>
          )}
          <Route
            path="/dashboard/shift-management"
            element={<ShiftManagement />}
          ></Route>
          <Route path="/dashboard/time-off" element={<TimeOff />}></Route>
          <Route
            path="/dashboard/help-support"
            element={<HelpSupport />}
          ></Route>
          <Route path="/dashboard/setting" element={<Setting />}></Route>
        </Route>

        <Route path="/" element={<LoginLayout />}>
          <Route index element={<Login />}></Route>
          <Route path="/middlepage" element={<JumpAuthorize />}></Route>
          <Route path="/select-organization" element={<Organization />}></Route>
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
};
